import StyledInput from "../../styledinput/StyledInput";
import {FaEnvelope, FaExclamationCircle, FaInfoCircle} from "react-icons/fa";
import {useState} from "react";

const ProfileContent_EMail = () => {

    const PageTitle = "E-Mail";
    const PageSubTitle = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam";
    const PageIcon = <FaEnvelope/>;

    const [Error, ShowError] = useState("");

    const _changeEMail = async () => {

    }

    return <>
        <div className="header">
            <div className="icon">
                {PageIcon}
            </div>
            <div className="titles">
                <div className="title">{PageTitle}</div>
                <div className="sub-title">{PageSubTitle}</div>
            </div>
        </div>

        <div className="input-row">
            <StyledInput
                className="row-input-box"
                inputType="email"
                label="Current E-Mail"
            />
        </div>
        <div className="input-row">
            <StyledInput
                className="row-input-box"
                inputType="email"
                label="New E-Mail"
            />
            <StyledInput
                className="row-input-box"
                inputType="email"
                label="Confirm new E-Mail"
            />
        </div>


        {Error !== "" && (<div className="error-text">
            <FaExclamationCircle/>
            <div className="text">
                {Error}
            </div>
        </div>)}


        <div className="info2">
            <FaInfoCircle/>
            <div className="text">
                After confirming, you will receive a confirmation email with a link.
            </div>
        </div>

        <div className="row">
            <button className="profile-btn-red" style={{marginRight:"15px"}} onClick={_changeEMail}>Change E-Mail</button>
            <button className="profile-btn-gray" onClick={() => {
                document.location.reload();
            }}>Cancel</button>
        </div>
    </>;
}

ProfileContent_EMail.props = {};
export default ProfileContent_EMail;