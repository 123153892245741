import './ProfileContent.css';
import ProfileContent_PersonalInformation from "../content_pages/ProfileContent_PersonalInformation";
import ProfileContent_Password from "../content_pages/ProfileContent_Password";
import ProfileContent_Notification from "../content_pages/ProfileContent_Notification";
import ProfileContent_AccountDeletion from "../content_pages/ProfileContent_AccountDeletion";
import ProfileContent_OrderHistory from "../content_pages/ProfileContent_OrderHistory";
import ProfileContent_PaymentMethods from "../content_pages/ProfileContent_PaymentMethods";
import ProfileContent_EMail from "../content_pages/ProfileContent_EMail";
import ProfileContent_ProfilePicture from "../content_pages/ProfileContent_ProfilePicture";

const ProfileContent = ({ ProfilePage }) => {

    const renderContent = () => {
        switch (ProfilePage) {
            case "#password":
                return <ProfileContent_Password />;
            case "#email":
                return <ProfileContent_EMail />;
            case "#notification":
                return <ProfileContent_Notification />;
            case "#delete-account":
                return <ProfileContent_AccountDeletion />;
            case "#order-history":
                return <ProfileContent_OrderHistory />;
            case "#payment-methods":
                return <ProfileContent_PaymentMethods />;
            case "#profile-picture":
                return <ProfileContent_ProfilePicture />;

            case "":
            case "#":
            default:
                return <ProfileContent_PersonalInformation />;
        }
    }


    return <div id="profile-content">
        {renderContent()}
    </div>;
}
ProfileContent.props = {};
export default ProfileContent;