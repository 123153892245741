import {FaHistory} from "react-icons/fa";
/*import {FaDownload, FaRepeat} from "react-icons/fa6";*/

const ProfileContent_OrderHistory = () => {

    const PageTitle = "Order History";
    const PageSubTitle = "This is an overview of all orders you've placed for products offered by CriseStudios, including websites, apps, and games.";
    const PageIcon = <FaHistory/>;


    return <>
        <div className="header">
            <div className="icon">
                {PageIcon}
            </div>
            <div className="titles">
                <div className="title">{PageTitle}</div>
                <div className="sub-title">{PageSubTitle}</div>
            </div>
        </div>

        <div className="order-history">
            <div className="order-history-header">
                <div>Product</div>
                <div>Amount</div>
            </div>

            <div className="items">
                {/*<div className="item">
                    <div className="left">
                        <div className="image">
                            <img src={""} alt="" draggable="false"/>
                        </div>

                        <div className="infos">
                            <div className="name">product name</div>
                            <div className="price">Price: X</div>
                            <div className="date">Date: XX:XX:XX</div>
                            <div className="transaction_id">TransactionId: XXXXXXXXXX</div>
                        </div>
                    </div>
                    <div className="amount">
                        <span>1</span>
                    </div>
                    <div className="buttons">
                        <FaDownload/>
                        <FaRepeat/>
                    </div>
                </div>*/}
            </div>

        </div>
    </>;
}

ProfileContent_OrderHistory.props = {};
export default ProfileContent_OrderHistory;