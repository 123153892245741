export const apiUrl = document.location.hostname.includes('localhost') ? 'http://localhost:1337/' : 'https://api.crisestudios.com/';

export const LINKS = {
    discord: "https://discord.gg/7seYQjqrwM",
    instagram: "https://instagram.com/CriseStudios",
    twitter: "https://twitter.com/Crise_Studios",
    facebook: "https://facebook.com/CriseCompany",
    github: "https://github.com/CriseStudios"
}

export function getAuth() {
    const authCookie = getCookie('auth');
    const userData = authCookie !== undefined ? JSON.parse(getCookie('user')) : {};
    const isLoggedIn = authCookie !== undefined && Object.entries(userData).length > 0;

    return {
        isLoggedIn,
        token: authCookie,
        user: userData
    }
}

export function GetCurrentYear() {
    const d = new Date();
    return d.getFullYear();
}



/**
 *
 * @param {string} birthday Pattern: yyyy-mm-dd
 */
export function calcAge(birthday = '') {
    if(birthday === undefined)
        return 0;
    return Math.floor((new Date() - new Date(birthday).getTime()) / 3.15576e+10);
}

/**
 * Simulate a click event.
 * @public
 * @param {Element} elem  the element to simulate a click on
 */
export const simulateClick = (elem) => {
    // Create our event (with options)
    const evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
    });
    // If cancelled, don't dispatch our event
    elem.dispatchEvent(evt);
};


export function setCookie(cname, cvalue, exHours) {
    if(!exHours) exHours = 1 * 24;
    let d = new Date();
    d.setTime(d.getTime() + (exHours*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return;
}
export function clearCookie(cname) {
    const expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
    document.cookie = cname + "=;" + expires + ";path=/";
}
export function clearCookies() {
    document.cookie = '';
}


export function getQuery(key) {
    let query = document.location.search;
    query = query.replace('?', '');
    let splitRAW = query.split('&');
    let split = {};
    splitRAW.forEach(raw => {
        let k = raw.split("=")[0];
        let v = raw.split("=")[1];
        split[k] = v;
    });
    if(split[key] !== undefined)
        return split[key];
    else
        return "";
}


export function setTitle(title = '') {
    document.title = title + " | CriseStudios.com";
}


export const onLogoutClick = async (silent = false) => {
    const auth = getCookie('auth');
    if(!auth) return;

    await sendPost('auth/logout', {auth: auth, logoutOnAllDevices: false});

    clearCookie('auth');
    clearCookie('user');

    if(!silent) createNotification("i", "Logged out!", 3);

    document.location = '/login';
};


export function getStorage() {
    return localStorage;
}
export function getStorageItem(key) {
    if (typeof(Storage) !== "undefined") {
        let data = localStorage[key];
        if(data) data = JSON.parse(data);
        return data;
    }
    else {
        //Use cookies instead..
        let data = getCookie(key);
        if(data) data = JSON.parse(data);
        return data;
    }
}
export function setStorageItem(key, value) {
    if (typeof(Storage) !== "undefined") {
        localStorage.setItem(key, JSON.stringify(value));
    }
    else {
        clearCookie(key);
        setCookie(key, JSON.stringify(value));
    }
}
export function removeStorageItem(key) {
    if (typeof(Storage) !== "undefined") {
        localStorage.removeItem(key);
        return;
    }
    else {
        clearCookie(key);
        return;
    }
}
export function clearStorage() {
    if (typeof(Storage) !== "undefined") {
        localStorage.clear();
        return;
    }
    else {
        clearCookies();
        return;
    }
}

export const fetchUserDataC = async (userId, callback) => {
    if(getStorageItem('userDataCache_'+userId.toString())) {
        if (typeof callback == "function")
            callback(getStorageItem('userDataCache_'+userId.toString()));
        return;
    }

    let response = await sendPost('crisestudios/user/get/'+userId);
    if(response.status === 'ok') {
        setStorageItem('userDataCache_'+userId.toString(), response);

        if (typeof callback == "function")
            callback(response);
        return;
    }
    else {
        if (typeof callback == "function")
            callback({status: 'error', message: '', data: {}});
        return;
    }
}
export const fetchUserData = async (userUUID = '') => {
    if(getStorageItem('userDataCache_'+userUUID)) {
        return getStorageItem('userDataCache_'+userUUID);
    }

    let response = await sendGet('crisestudios/user/get/'+userUUID);
    if(response.status === 'ok') {
        setStorageItem('userDataCache_'+userUUID, response);
        return response;
    }
    else {
        return {status: 'error', message: '', data: {}};
    }
}

export const fetchUserDataByNameC = async (username, callback) => {
    if(getStorageItem('userDataCacheByName_'+username)) {
        if (typeof callback === "function")
            callback(getStorageItem('userDataCacheByName_'+username));
        return;
    }

    let response = await sendPost('crisestudios/user/get/name/'+username);
    if(response.status === 'ok') {
        setStorageItem('userDataCacheByName_'+username, response);
        if (typeof callback == "function")
            callback(response);
        return;
    }
    else {
        if (typeof callback === "function")
            callback({status: 'error', message: response.message, data: {}});
        return;
    }
}
export const fetchUserDataByName = async (username) => {
    /*if(getStorageItem('userDataCacheByName_'+username)) {
        return getStorageItem('userDataCacheByName_'+username);
    }*/

    let response = await sendPost('crisestudios/user/get/name/'+username);
    if(response.status === 'ok') {
        /*setStorageItem('userDataCacheByName_'+username, response);*/
        return response;
    }
    else {
        return {status: 'error', message: response.message, data: {}};
    }
}

export const fetchAccount = async (auth = '') => {
    /*if(getStorageItem('account_'+auth)) {
        return getStorageItem('account_'+auth);
    }*/

    let response = await sendPost('crisestudios/user/myaccount/get/', {auth: auth});
    if(response.status === 'ok') {
        setStorageItem('account_'+auth, response);
        return response;
    }
    return response;
}

export const fetchSettings = async (auth = '') => {
    /*if(getStorageItem('settings_'+auth)) {
        return getStorageItem('settings_'+auth);
    }*/
    let response = await sendPost('crisestudios/user/settings/get', {
        auth: auth
    });
    /*if(response.status === 'ok') setStorageItem('settings_'+auth, response);*/
    return response;
}

export const UUID = () => {
    let s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}


export function initNotification(notificationData = {}) {
    const uuid = notificationData.id;
    const type = notificationData.type;
    const aliveTime = notificationData.aliveTime;
    const message = notificationData.message;

    const notificationSystem = document.querySelector('#notification_system');
    if(notificationSystem) {
        const notificationList = notificationSystem.getElementsByClassName('notification_list')[0];
        const count = notificationList.childElementCount;
        if(notificationList && count < 5) {
            let typeClass = type === 'i' ? 'notification_item_info' : type === '!' ? 'notification_item_error' : type === '?' ? 'notification_item_question' : type === '+' ? 'notification_item_success' : '';
            let typeIcon = type === 'i' ? 'i' : type === '!' ? '!' : type === '?' ? '?' : type === '+' ? '✓' : '';


            const el = document.createElement('div');
            el.classList.add('notification_item');
            el.dataset.uuid = uuid;
            if(typeClass !== '') el.classList.add(typeClass);
            el.classList.add('in');
            el.innerHTML = `<div class="close" title="Close">x</div>
                            <div class="icon">${typeIcon}</div>
                            <div class="message">${message}</div>`;
            el.addEventListener('animationend', (e) => {el.classList.remove('in');});
            el.getElementsByClassName('close')[0].addEventListener('click', () => {
                deleteNotification(uuid);
            });
            notificationList.appendChild(el);

            setTimeout(() => {
                deleteNotification(el.dataset.uuid);
            }, 1000 * aliveTime);

            return true;
        }
        else if(count > 5) {
            console.error("Max count for visible notifications reached!");
        }
    } else {
        console.error("NotificationSystem not found!");
        return false;
    }
}
export function createNotification(type = '', message = '', aliveTime = 5) {
    const uuid = UUID();
    const data = {
        id: uuid,
        create_date: new Date(),
        aliveTime: aliveTime,
        type: type,
        message: message
    };
    if(initNotification(data)) {
        let notifications = getStorageItem("notification_system");
        if (!notifications) notifications = [];
        notifications.push(data)
        setStorageItem("notification_system", notifications);
    }
}
export function deleteNotification(notificationId = '') {
    const el = document.querySelector("[data-uuid='"+notificationId+"']");
    if(!el) return;
    el.classList.add('out')

    setTimeout(() => {
        el.remove();
    }, 900);

    let notifications = getStorageItem("notification_system");
    if(notifications) {

        let target;
        let count = -1;

        for(let not of notifications) {
            count++;
            if(not.id === notificationId) {
                target = not;
                break;
            }
        }

        if(target) {
            notifications.splice(count, 1);
            setStorageItem("notification_system", notifications);
        }

    }
}

export const getImage = (image = '') => {
    if(image === '' || image === ' ') return '';
    if(!image.startsWith(apiUrl)) {
        image = apiUrl.slice(0, -1) + image;
    }
    return image;
}
export const getAPIImage = (image = '') => {
    if(image === '' || image === ' ') return '';
    if(!image.startsWith(apiUrl)) {
        image = apiUrl + "images/" + image;
    }
    return image;
}


export function htmlEntities(str) {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}
export function isValidEmail(val) {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(val);
}
export function isValidMobileNumber(number) {
    let regex = /((07)|((\+|00)447)){1}[0-9]{9}\b/,
        result = regex.test(number);
    return result;
}


export async function sendGet(url = '', body = {}) {
    const authData = getAuth();
    try {
        if(body === null || body === undefined) body = {};

        let result = await fetch(apiUrl+url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json, text/javascript',
                'Authorization': authData.isLoggedIn ? authData.token : ''
            }
        });
        return result.json();
    } catch(err) {
        return {status: 'error', message: 'No response from api..\nError:'+err};
    }
}
export async function sendPost(url = '', body = {}) {
    const authData = getAuth();
    try {
        if(body === null || body === undefined) body = {};

        let result = await fetch(apiUrl+url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json, text/javascript',
                'Authorization': authData.isLoggedIn ? authData.token : ''
            },
            body: JSON.stringify(body)
        });
        return result.json();
    } catch(err) {
        return {status: 'error', message: 'No response from api..'};
    }
}
export async function sendDelete(url = '', body = {}) {
    const authData = getAuth();
    try {
        if(body === null || body === undefined) body = {};

        let result = await fetch(apiUrl+url, {
            method: 'DELETE',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json, text/javascript',
                'Authorization': authData.isLoggedIn ? authData.token : ''
            },
            body: JSON.stringify(body)
        });
        return result.json();
    } catch(err) {
        return {status: 'error', message: 'No response from api..'};
    }
}
export async function sendPatch(url = '', body = {}) {
    const authData = getAuth();
    try {
        if(body === null || body === undefined) body = {};

        let result = await fetch(apiUrl+url, {
            method: 'PATCH',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json, text/javascript',
                'Authorization': authData.isLoggedIn ? authData.token : ''
            },
            body: JSON.stringify(body)
        });
        return result.json();
    } catch(err) {
        return {status: 'error', message: 'No response from api..'};
    }
}
export async function sendUpdate(url = '', body = {}) {
    const authData = getAuth();
    try {
        if(body === null || body === undefined) body = {};

        let result = await fetch(apiUrl+url, {
            method: 'PUT',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json, text/javascript',
                'Authorization': authData.isLoggedIn ? authData.token : ''
            },
            body: JSON.stringify(body)
        });
        return result.json();
    } catch(err) {
        return {status: 'error', message: 'No response from api..'};
    }
}

export async function uploadPicture(url = '', picture) {
    const authData = getAuth();
    try {
        const formData = new FormData();
        formData.append("picture", picture);

        let result = await fetch(apiUrl+url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                /*'Content-Type': 'application/json; charset=UTF-8',*/
                'Accept': 'application/json, text/javascript',
                'Authorization': authData.isLoggedIn ? authData.token : ''
            },
            body: formData
        });
        return result.json();
    } catch(err) {
        return {status: 'error', message: 'No response from api..'};
    }
}
