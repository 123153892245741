import "./SixDigitCodeInput.css";
import {useEffect} from "react";

const SixDigitCodeInput = ({value = '', onChange = function (code = ''){}}) => {
    let numberCodeInputs = [];

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        getInputs();

        if(value.length > 0) {
            numberCodeInputs[0].value = value;
            handleInput({target: numberCodeInputs[0]});
        }
    }, []);
    const getInputs = () => {
        if(numberCodeInputs.length === 0) {
            numberCodeInputs = [];
            for(let i = 1; i < 7; i++) numberCodeInputs.push(document.querySelector(".sixDigitCodeInput"+i));
        }
    }

    const handleInput = ({target}) => {
        getInputs();

        if (!target.value.length) return target.value = null;
        let currentIndex = Number(target.className.substring(target.className.length-1))-1;
        const inputLength = target.value.length;

        if (inputLength > 1) {
            let _text = target.value.slice(0, inputLength > 6 ? 6 : inputLength);
            const inputValues = _text.split('');

            inputValues.forEach((value, valueIndex) => {
                const nextValueIndex = currentIndex + valueIndex;
                if (nextValueIndex >= numberCodeInputs.length)return;
                numberCodeInputs[nextValueIndex].value = value;
            });

            currentIndex += inputValues.length - 2;
        }

        const nextIndex = currentIndex + 1;
        if(nextIndex < numberCodeInputs.length) numberCodeInputs[nextIndex].focus();

        let code = "";
        for(let i = 0; i < 6; i++) code += numberCodeInputs[i].value;
        if(onChange !== null && typeof onChange === 'function') onChange(code);
    }
    const handleKeyDown = (e) => {
        getInputs();
        const {code, target} = e;

        const currentIndex = Number(target.className.substring(target.className.length-1))-1;
        const previousIndex = currentIndex - 1;
        const nextIndex = currentIndex + 1;

        const hasPreviousIndex = previousIndex >= 0;
        const hasNextIndex = nextIndex <= numberCodeInputs.length - 1

        switch(code) {
            case 'ArrowLeft':
            case 'ArrowUp':
                if (hasPreviousIndex) numberCodeInputs[previousIndex].focus();
                e.preventDefault();
                break;

            case 'ArrowRight':
            case 'ArrowDown':
                if (hasNextIndex) numberCodeInputs[nextIndex].focus();
                e.preventDefault();
                break;
            case 'Backspace':
                if (!e.target.value.length && hasPreviousIndex) {
                    numberCodeInputs[previousIndex].value = null;
                    numberCodeInputs[previousIndex].focus();
                }
                break;
            default:
                break;
        }

        let inputCode = "";
        for(let i = 0; i < 6; i++) inputCode += numberCodeInputs[i].value;
        if(onChange !== null && typeof onChange === 'function') onChange(inputCode);
    }

    return <div id="sixDigitCodeInput" onInput={handleInput} onKeyDown={handleKeyDown}>
        <input className="sixDigitCodeInput1" type="text" placeholder="0" autoCorrect="off" autoComplete="off" autoCapitalize="off" />
        <input className="sixDigitCodeInput2" type="text" placeholder="0" autoCorrect="off" autoComplete="off" autoCapitalize="off" />
        <input className="sixDigitCodeInput3" type="text" placeholder="0" autoCorrect="off" autoComplete="off" autoCapitalize="off" />
        <input className="sixDigitCodeInput4" type="text" placeholder="0" autoCorrect="off" autoComplete="off" autoCapitalize="off" />
        <input className="sixDigitCodeInput5" type="text" placeholder="0" autoCorrect="off" autoComplete="off" autoCapitalize="off" />
        <input className="sixDigitCodeInput6" type="text" placeholder="0" autoCorrect="off" autoComplete="off" autoCapitalize="off" />
    </div>;
}

export default SixDigitCodeInput;