import {FaExclamationCircle} from "react-icons/fa";
import {useEffect, useRef, useState} from "react";
import {FaCamera} from "react-icons/fa6";
import Croppr from "croppr";
import 'croppr/dist/croppr.css';
import {uploadPicture} from "../../../utils";

const ProfileContent_ProfilePicture = () => {

    const PageTitle = "Profile Picture";
    const PageSubTitle = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam";
    const PageIcon = <FaCamera/>;

    const [Error, ShowError] = useState("");
    const [UpdateBtnDisabled, SetUpdateBtnDisabled] = useState(true);
    const [PreviewProfilePictureFile, SetPreviewProfilePictureFile] = useState(null);
    const [PreviewProfilePictureFile2, SetPreviewProfilePictureFile2] = useState(null);
    const refCropper = useRef(null);
    const refCropperImg = useRef(null);

    const _inputProfilePicture = async () => {
        const input = document.getElementById("hiddenInputProfilePicture");
        if (input === undefined) return;
        input.click();
    }
    const _previewProfilePicture = async () => {
        const input = document.getElementById("hiddenInputProfilePicture");
        if (input === undefined) return;
        if (input.files.length <= 0) return;

        const pic = document.getElementById('profilePicture');
        if (pic === undefined) return;

        const file = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            if (Error !== "") ShowError("");
            if (e.target.result !== null) {
                SetPreviewProfilePictureFile(e.target.result);
                SetUpdateBtnDisabled(false);

                let i = new Image();
                i.src = e.target.result;
                refCropperImg.current = i;
            }
        }
        reader.onerror = (err) => {
            if (UpdateBtnDisabled !== true) SetUpdateBtnDisabled(true);
            ShowError("Something went wrong while processing the image");
        }
        reader.onloadend = (e) => {
            setTimeout(() => {
                const img = document.getElementById("profile-image-cropper-img");
                let cropper = new Croppr(img, {
                    aspectRatio: 1,
                    minSize: [100, 100, 'px'],
                    onCropEnd() {
                        _previewImages();
                    }
                });
                refCropper.current = cropper;
                _previewImages();
            }, 50);
        }
        reader.readAsDataURL(file);
    }
    const _previewImages = () => {
        let croppedImage = _GetCroppedImage();
        if (croppedImage === null) return;
        SetPreviewProfilePictureFile2(croppedImage);
    }
    const _GetCroppedImage = () => {
        if (refCropperImg.current === null) return null;
        let cropper = refCropper.current;
        if (cropper === null) return null;
        const vbox = cropper.getValue();
        const width = vbox.width;
        const height = vbox.height;
        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(refCropperImg.current, vbox.x, vbox.y, width, height, 0, 0, width, height);
        let compress = parseInt("50", 10) / 100;
        let dataURL = canvas.toDataURL('image/jpeg', compress);
        return dataURL;
    }
    const _DataURLtoBlob = (dataURL) => {
        let arr = dataURL.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) u8arr[n] = bstr.charCodeAt(n);
        return new Blob([u8arr], {type: mime});
    }
    const _uploadProfilePicture = async () => {
        const _navbarProfilePicture = document.getElementById("navbarProfilePicture");

        let croppedImage = _GetCroppedImage();
        if (croppedImage === null) return;
        _navbarProfilePicture.src = croppedImage;

        const f = async () => {
            let res = await uploadPicture("crisestudios/profile/update/picture", _DataURLtoBlob(croppedImage));
            if (res.status === "ok") document.location = "/profile"
            else ShowError(res.message);
        }
        await f();
    }

    return <>
        <div className="header">
            <div className="icon">
                {PageIcon}
            </div>
            <div className="titles">
                <div className="title">{PageTitle}</div>
                <div className="sub-title">{PageSubTitle}</div>
            </div>
        </div>

        <input
            type={"file"}
            name="picture"
            id="hiddenInputProfilePicture"
            accept="image/*"
            onChange={_previewProfilePicture}
            hidden/>

        {PreviewProfilePictureFile !== null && (<>
            <div className="profile-image-preview-text">Preview</div>
            <div className="profile-image-previews">
                <div className="profile-image-preview-round">
                    <img src={PreviewProfilePictureFile2 !== null ? PreviewProfilePictureFile2 : ""} alt=""
                         draggable="false"/>
                </div>
                <div className="profile-image-preview-square">
                    <img src={PreviewProfilePictureFile2 !== null ? PreviewProfilePictureFile2 : ""} alt=""
                         draggable="false"/>
                </div>
            </div>
            <div className="profile-image-cropper">
                {PreviewProfilePictureFile !== null && (
                    <img src={PreviewProfilePictureFile} alt="" draggable="false" id="profile-image-cropper-img"/>)}
            </div>
        </>)}

        <button className="profile-btn profile-btn-input" onClick={_inputProfilePicture}>Select Image</button>


        {Error !== "" && (<div className="error-text">
            <FaExclamationCircle/>
            <div className="text">
                {Error}
            </div>
        </div>)}


        <div className="row">
            <button className="profile-btn"
                    style={{marginRight: "15px"}}
                    onClick={_uploadProfilePicture}
                    disabled={UpdateBtnDisabled}>
                Upload
            </button>
            <button className="profile-btn-gray" onClick={() => {
                document.location.reload();
            }}>Cancel
            </button>
        </div>
    </>;
}

ProfileContent_ProfilePicture.props = {};
export default ProfileContent_ProfilePicture;