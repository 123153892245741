import {FaUserLock} from "react-icons/fa6";
import StyledInput from "../../styledinput/StyledInput";
import {FaExclamationCircle, FaInfoCircle} from "react-icons/fa";
import {useState} from "react";

const ProfileContent_Password = () => {

    const PageTitle = "Password";
    const PageSubTitle = "For your security, please keep your password confidential and select a strong one. Consider using a variety of symbols, including numbers and special characters, to enhance its strength.";
    const PageIcon = <FaUserLock/>;

    const [Error, ShowError] = useState("");

    const _changePassword = async () => {

    }

    return <>
        <div className="header">
            <div className="icon">
                {PageIcon}
            </div>
            <div className="titles">
                <div className="title">{PageTitle}</div>
                <div className="sub-title">{PageSubTitle}</div>
            </div>
        </div>

        <div className="input-row">
            <StyledInput
                className="row-input-box"
                inputType="password"
                label="Current Password"
            />
        </div>
        <div className="input-row">
            <StyledInput
                className="row-input-box"
                inputType="password"
                label="New Password"
            />
            <StyledInput
                className="row-input-box"
                inputType="password"
                label="Confirm Password"
            />
        </div>

        {Error !== "" && (<div className="error-text">
            <FaExclamationCircle/>
            <div className="text">
                {Error}
            </div>
        </div>)}

        <div className="info2">
            <FaInfoCircle/>
            <div className="text">
                After confirming, you will be logged out and receive a confirmation email.
            </div>
        </div>

        <div className="row">
            <button className="profile-btn-red" style={{marginRight:"15px"}} onClick={_changePassword}>Change Password</button>
            <button className="profile-btn-gray" onClick={() => {
                document.location.reload();
            }}>Cancel</button>
        </div>
    </>;
}

ProfileContent_Password.props = {};
export default ProfileContent_Password;