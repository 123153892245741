import './Home.css'
import {sendGet, setTitle} from "../../utils";
import logo from "../../images/logos/logo_square_v1_blured_outline_black.png";
import ImageCarousel from '../../components/imagecarousel/ImageCarousel'
import {
    FaBug,
    FaEnvelope,
    FaGamepad,
    FaLaptopCode, FaQuestion,
    FaQuestionCircle,
    FaServer,
    FaTabletAlt,
    FaTicketAlt
} from "react-icons/fa";
import {useEffect, useState} from "react";


const Home = ({title}) => {
    setTitle(title);

    const aboutUsText = 'We are a small company based in Germany, currently comprising two dedicated employees. Our primary focus lies in designing websites and apps, as well as developing various digital products, including websites, apps, and games. We strive to create diverse and enjoyable experiences for our community, aiming to enhance daily life through our creations. Inspired by the tradition of German quality and the reputation of "Made in Germany," we are committed to upholding these standards in all our endeavors. With a relentless pursuit of efficiency and quality, we aim to ensure the satisfaction and joy of our users at every turn.';
    const whyUsText = "Because we consistently strive to deliver top-notch performance and unparalleled quality in everything we do.";


    const [RecentNewsData, SetRecentNewsData] = useState(null);
    useEffect(() => {
        const f = async () => {
            let res = await sendGet("crisestudios/news/get/recent");
            if(res.status === "ok") {
                let d = [];
                res.data.forEach(n => {
                    d.push({
                        media: {
                            isImage: true,
                            file: n.bannerImage
                        },
                        title: n.title,
                        text: n.shortDescription,
                        button: "/news/"+n.uuid
                    });
                });
                SetRecentNewsData(d);
            } else {
                if(RecentNewsData !== null) SetRecentNewsData(null);
            }
        }
        f();
    }, []);

    return (
        <div className="home-container">

            <section className="image_carousel">
                <ImageCarousel delay={8} data={RecentNewsData}/>
            </section>

            <section className="about_us">
                <div className="about_us_container">
                    <div className="content">
                        <span>{aboutUsText}</span>
                        <div className="arrow_right">
                            <div className="arrow_right_inner">
                                <div className="arrow_right_inner_2"/>
                            </div>
                        </div>
                    </div>
                    <div className="logo">
                        <div className="title">About Us</div>
                        <img src={logo} alt="" draggable="false"/>
                    </div>
                </div>
            </section>

            <section className="our_services">
                <div className="our_services_container">

                    <div className="background_container">
                        <div className="title">Our Focus</div>
                    </div>

                    <div className="services_container">

                        <div className="item">
                            <div className="icon">
                                <FaLaptopCode/>
                            </div>
                            <div className="title">Web Development & Design</div>
                            <div className="description">
                                In the realm of web development and design, we specialize in crafting visually appealing
                                and functional websites tailored to our needs.
                            </div>

                            <a href="/services/web">
                                <div className="more">More</div>
                            </a>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <FaTabletAlt/>
                            </div>
                            <div className="title">App Development & Design</div>
                            <div className="description">
                                Our expertise in app development and design allows us to create intuitive and innovative
                                mobile applications that enhance user experiences across various platforms.
                            </div>

                            <a href="/services/app">
                                <div className="more">More</div>
                            </a>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <FaGamepad/>
                            </div>
                            <div className="title">Game Development</div>
                            <div className="description">
                                When it comes to game development, we are dedicated to conceptualizing and bringing to
                                life engaging and immersive gaming experiences that captivate players.
                            </div>

                            <a href="/services/game">
                                <div className="more">More</div>
                            </a>
                        </div>

                    </div>
                </div>
            </section>

            <section className="need_help">
                <div className="title">Need help?</div>
                <div className="need_help_container">
                    <a href="/support/faq">
                        <div className="item">
                            <span>FAQ</span>
                            <FaQuestionCircle/>
                        </div>
                    </a>
                    <a href="/support">
                        <div className="item">
                            <span>Ticket</span>
                            <FaTicketAlt/>
                        </div>
                    </a>
                    <a href="mailto:contact@crisestudios.com">
                        <div className="item">
                            <span>eMail</span>
                            <FaEnvelope/>
                        </div>
                    </a>
                    <a href="/support/bug">
                        <div className="item">
                            <span>Bug</span>
                            <FaBug/>
                        </div>
                    </a>
                </div>
            </section>

            <section className="why_us">
                <div className="logo">
                    <img src="../../images/logos/white/logo_square_v1.png" draggable="false" alt=""/>
                </div>
                <div className="image">
                    <img src="../../images/backgrounds/home_stock_code.jpg" draggable="false" alt=""/>
                </div>
                <div className="text_container">
                    <div className="title">Why us?</div>
                    <div className="text">{whyUsText}</div>
                </div>
            </section>

            <section className="server_location">
                <div className="left">
                    <FaServer/>
                    <div>
                        <span className="title">Server location</span>
                        <span className="description">Germany</span>
                    </div>
                </div>
                <div className="right">
                    <FaQuestion/>
                    <div>
                        <span className="title">Support</span>
                        <span className="description">24/7</span>
                    </div>
                </div>
            </section>

            {/*<section className="community_pictures_and_videos">
                <div className="top">
                    <div className="content">
                        <div className="background" />

                        <FaRegImages />
                        <div className="title">Community Pictures & Videos</div>
                        <div className="description">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                        </div>
                    </div>
                </div>
            </section>*/}

            <section className="register_now">
                <div className="title">Register now for free</div>
                <div className="description">
                    Join our vibrant community and become an integral part of it!
                    <br/>
                    We eagerly welcome individuals who are passionate about sharing their
                    <br/>
                    ideas and contributing their motivation to enhance our projects even further!
                </div>
                <div className="spacer">
                    <div/>
                    <div/>
                    <div/>
                </div>
                <a href="/register">
                    <button>Sign Up</button>
                </a>
            </section>

        </div>
    );
}

Home.defaultProps = {
    title: 'Home'
}

export default Home;