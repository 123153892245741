import './News.css'
import {getAPIImage, getAuth, sendGet, setTitle} from "../../utils";
import {FaPlus} from "react-icons/fa6";
import {useEffect, useState} from "react";


const News = ({title}) => {
    setTitle(title);
    const authData = getAuth();

    const [Data, SetData] = useState(null);
    const [MoreNewsData, SetMoreNewsData] = useState([]);
    const [DisableMoreNewsBtn, SetDisableMoreNewsBtn] = useState(false);
    const [MoreNewsOffset, SetMoreNewsOffset] = useState(1);

    const fetchMoreNews = async () => {
        let moreNews = await sendGet("crisestudios/news/get/recent/"+MoreNewsOffset);

        if(moreNews.status === "ok") {
            let fetchedAmount = moreNews.data.length;
            let data = [...MoreNewsData];
            if(fetchedAmount > 0) {
                data.push(...moreNews.data);
                SetMoreNewsData(data);
                SetMoreNewsOffset(MoreNewsOffset + 1);
                if(fetchedAmount < 3) SetDisableMoreNewsBtn(true);
            } else SetDisableMoreNewsBtn(true);
        } else SetDisableMoreNewsBtn(true);
    }

    useEffect(() => {
        const f = async () => {
            let res = await sendGet("crisestudios/news/get/recent");
            if(res.status === "ok") SetData(res.data);
        }
        f();
        fetchMoreNews();
    }, []);



    return (
        <div id="wrapper-news">
            <div className="news-header">
                <div className="banner">
                    Recent Updates & News
                </div>
            </div>

            <section className="row recent_news">

                {Data !== null && Data.map((news, i) => {
                    let date = news.published.date.split(" ");
                    let month = date[2];
                    let day = date[1];

                    return <div className="recent_news_box" key={i}>
                        <div className="header">
                            <img src={getAPIImage("crisestudios/news/"+news.bannerImage)} alt="" draggable="false"/>
                            <div className="date_box">
                                <span>{month}</span>
                                <span>{day}</span>
                            </div>
                        </div>
                        <div className="content">
                            <div className="project_name">{news.project.shortName}</div>
                            <div className="title">{news.title}</div>
                            <div className="description">{news.shortDescription}</div>
                            <div className="news-footer">
                                <div className="creator">
                                    by <span>{news.creator.username}</span>
                                </div>
                                <a href={"/news/"+news.uuid}>
                                    <span>Read more</span>
                                </a>
                            </div>
                        </div>
                    </div>;
                })}

            </section>

            <section className="newsletter">
                <div className="more_news">More news</div>
                <div className="newsletter_info">
                    <a href={authData.isLoggedIn ? "/profile#notification" : "/login?re=/profile#notification"}><span>Click here if you want to stay updated with the latest news.</span></a>
                </div>
            </section>

            <section className="column news">
                {MoreNewsData.length > 0 && MoreNewsData.map((news, i) => {
                    return (
                        <div className="news_box" key={i}>
                            <div className="content_image">
                                <img
                                    src={getAPIImage("crisestudios/news/"+news.bannerImage)}
                                    alt=""
                                    draggable="false"/>
                            </div>
                            <div className="content_text">
                                <div className="news-title">
                                    {news.title}
                                </div>
                                <div className="news-description">
                                    {news.shortDescription}
                                </div>
                                <a href={"/news/"+news.uuid}>Read more</a>
                            </div>
                        </div>
                    );
                })}
            </section>

            <section className="load_more">
                <button className="btn-company" onClick={fetchMoreNews} disabled={DisableMoreNewsBtn}>Load
                    More <FaPlus/></button>
            </section>
        </div>
    );
}

News.defaultProps = {
    title: 'News'
}

export default News;