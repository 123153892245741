import {FaGenderless, FaPerson} from "react-icons/fa6";
import StyledInput from "../../styledinput/StyledInput";
import {FaCheck, FaExclamationCircle, FaInfoCircle} from "react-icons/fa";
import {MdFemale, MdMale} from "react-icons/md";
import {useEffect, useState} from "react";
import {createNotification, sendGet, sendUpdate} from "../../../utils";
import validator from "validator/es";

const ProfileContent_PersonalInformation = () => {

    const PageTitle = "Personal Information";
    const PageSubTitle = "Here, you have the option to update your personal information to ensure it accurately reflects your account details. Be sure to review your information regularly to ensure it remains correct and up-to-date.";
    const PageIcon = <FaPerson/>;

    const genders = [
        {
            id: 1,
            text: "Male",
            icon: <MdMale className="icon"/>
        },
        {
            id: 2,
            text: "Female",
            icon: <MdFemale className="icon"/>
        },
        {
            id: 0,
            text: "Genderless",
            icon: <FaGenderless className="icon"/>
        }
    ];
    const [SelectedGender, SetSelectedGender] = useState(0);

    const [Data, SetData] = useState(null);
    useEffect(() => {
        const f = async () => {
            const res = await sendGet("crisestudios/profile/get/personal_information");
            if (res.status === "ok") {
                SetData(res.data);

                let genderId = res.data.gender;
                if (genderId === "") genderId = 0;

                try {
                    genderId = parseInt(genderId);
                } catch (e) {
                    genderId = 0;
                }

                SetSelectedGender(genderId);
            }
        }
        f();


        const birthday = document.getElementById("inputBirthday");
        birthday.max = new Date().toISOString().split("T")[0];

    }, []);

    const [Error, ShowError] = useState("");

    const SaveChanges = async () => {
        const firstname = document.getElementById("inputFirstname").value;
        const lastname = document.getElementById("inputLastname").value;
        const phone = document.getElementById("inputPhone").value;
        const birthday = document.getElementById("inputBirthday").value;

        /*if(!validator.isMobilePhone(phone)) {
            ShowError("");
            return;
        }*/

        if (firstname.length > 16) {
            ShowError("I'm sorry but the firstname is limited to 16 characters.");
            return;
        }
        if (lastname.length > 16) {
            ShowError("I'm sorry but the lastname is limited to 16 characters.");
            return;
        }
        if (!validator.isDate(birthday)) {
            ShowError("Please enter a valid date for your birthday");
            return;
        }
        if(Error !== "") ShowError("");

        let res = await sendUpdate("crisestudios/profile/update/personal_information", {
            firstname,
            lastname,
            phone: "",
            birthday,
            gender: SelectedGender
        });

        if(res.status === "ok") createNotification("i", "Updated Personal Information!", 3);
        else createNotification("i", res.message, 5);

        setTimeout(() => {
            document.location.reload();
        }, 10);
    }

    return <>
        <div className="header">
            <div className="icon">
                {PageIcon}
            </div>
            <div className="titles">
                <div className="title">{PageTitle}</div>
                <div className="sub-title">{PageSubTitle}</div>
            </div>
        </div>

        <div className="input-row">
            <StyledInput
                id="inputFirstname"
                className="row-input-box"
                inputType="text"
                label="First name"
                placeholder="Max"
                value={Data !== null ? Data.firstname : ""}
            />
            <StyledInput
                id="inputLastname"
                className="row-input-box"
                inputType="text"
                label="Last name"
                placeholder="Mustermann"
                value={Data !== null ? Data.lastname : ""}
            />
        </div>
        <div className="input-row">
            <StyledInput
                id="inputPhone"
                className="row-input-box"
                inputType="tel"
                label="Phone"
                placeholder="(49) 0192 25255"
                value={Data !== null ? Data.phone : ""}
            />
            <StyledInput
                id="inputBirthday"
                className="row-input-box"
                inputType="date"
                label="Date of Birth"
                placeholder=""
                value={Data !== null ? Data.birthday : ""}
            />
        </div>

        <div className="row">
            {
                SelectedGender !== null && (
                    genders.map((gender, i) => {
                        return <div key={i} className={"gender-box " + (SelectedGender === gender.id ? "selected" : "")}
                                    onClick={() => {
                                        SetSelectedGender(gender.id);
                                    }}>
                            {SelectedGender === gender.id && <FaCheck className="check"/>}
                            {gender.icon}
                            <span>{gender.text}</span>
                        </div>;
                    })
                )
            }
        </div>

        <div className="info">
            <FaInfoCircle/>
            <div className="text">
                Before saving, double-check your information for accuracy. This data is solely visible to you and assists us in enhancing your comfort and overall experience.
            </div>
        </div>

        {Error !== "" && (<div className="error-text">
            <FaExclamationCircle/>
            <div className="text">
                {Error}
            </div>
        </div>)}

        <div className="row">
            <button className="profile-btn" style={{marginRight: "15px"}} onClick={SaveChanges}>Save</button>
            <button className="profile-btn-gray" onClick={() => {
                document.location.reload();
            }}>Cancel</button>
        </div>
    </>;
}

ProfileContent_PersonalInformation.props = {};
export default ProfileContent_PersonalInformation;