import './css/core/core.css';
import './css/core/colors.css';
import './css/core/animations.css';
import './css/core/button.css';
import './css/Auth.css';

import {createRoot} from "react-dom/client";

import React from 'react';
import App from './App';
const root = createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();