import {bool, func, string} from "prop-types";
import {useState} from "react";

const Slider = ({ toggled = false, OnToggle, dataName }) => {
    const [IsToggled, Toggle] = useState(toggled);

    return <label className="switch">
        <input type="checkbox" checked={IsToggled} onChange={(e) => {
            Toggle(e.target.checked);
            if(typeof OnToggle === "function") OnToggle(e.target, e.target.checked);
        }}
        data-name={dataName}/>
        <span className="slider round"/>
    </label>;
}
Slider.props = {
    toggle: bool,
    OnToggle: func,
    dataName: string
}
export default Slider;