import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';

import Home from './pages/home/Home';
import Team from "./pages/team/Team";
import Profile from "./pages/profile/Profile";
import Projects from "./pages/projects/Projects";

import Login from "./pages/auth/login/Login";
import Register from "./pages/auth/register/Register";
import VerifyEmail from "./pages/auth/verify/VerifyEmail";
import ForgotPassword from "./pages/auth/forgot/ForgotPassword";

import Imprint from "./pages/service/Imprint";
import PrivacyPolicy from "./pages/service/PrivacyPolicy";
import TermsOfService from "./pages/service/TermsOfService";


import NoPage from "./pages/nopage/NoPage";

import {FaAngleUp} from "react-icons/fa";
import {useEffect} from "react";
import {getStorageItem, initNotification} from "./utils";
import CookieConsent from "react-cookie-consent";
import ProjectsView from "./pages/projects/ProjectsView";
import Jobs from "./pages/jobs/Jobs";
import News from "./pages/news/News";
import NewsView from "./pages/news/NewsView";



function App() {
    let navbarItems = [
        {
            text: "Projects",
            link: "/projects",
            highlighted: false
        },
        {
            text: "Home",
            link: "/",
            highlighted: false
        },
        {
            text: "News",
            link: "/news",
            highlighted: false
        },
        /*{
            text: "Support",
            link: "/support",
            highlighted: false
        }*/
    ];

    let fullHost = document.location.pathname;
    let hostSplit = fullHost.split('/');
    let host = '/'+(hostSplit.length > 1 ? hostSplit[1] : '');

    for(let item of navbarItems) {
        item.highlighted = false;

        if(host.length > 1 && item.link.includes(host))
            item.highlighted = true;

        if(host === "/" && item.text === 'Home')
            item.highlighted = true;
    }

    let scrollToTopEL;
    const scrollToTopOnWindowScroll = () => {
        if(!scrollToTopEL) scrollToTopEL = document.querySelector('#scrollToTop');
        if(!scrollToTopEL) return;

        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            scrollToTopEL.classList.add('active');
        } else {
            scrollToTopEL.classList.remove('active');
        }
    }
    function scrollToTopOnClick() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        setTimeout(() => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }, 500);
    }

    useEffect(() => {
        document.body.addEventListener('scroll', () => {
            scrollToTopOnWindowScroll();
        })
    })
    useEffect(() => {
        const notificationSystem = document.querySelector('#notification_system');
        if(notificationSystem) {
            const notificationList = notificationSystem.getElementsByClassName('notification_list')[0];
            if (notificationList) {
                notificationList.innerHTML = '';

                let notifications = getStorageItem("notification_system");
                if(notifications) {
                    for(let not of notifications) {
                        initNotification(not);
                    }
                }
            }
        }
    });

  return (
      <BrowserRouter>
          <div className="wrapper">

              {host !== "/register" && host !== "/login" && (<Navbar items={navbarItems} />)}
              <Routes>
                  <Route index element={<Home />} />
                  <Route path="/jobs" element={<Jobs />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/news" element={<News />} />
                  <Route path="/news/:newsId" element={<NewsView />} />
                  <Route path="/profile" element={<Profile />} />
                  {/*<Route path="/profile/:targetUsername" element={<Profile />} />*/}
                  {/*<Route path="/support" element={<Support />} />*/}
                  <Route path="/projects" element={<Projects />} />
                  <Route path="/projects/view" element={<ProjectsView />} />

                  {/*<Route path="/forum" element={<Forum />} />
                  <Route path="/forum/:categoryId" element={<Forum_CategoryView />} />*/}

                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/verify" element={<VerifyEmail />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />

                  <Route path="/imprint" element={<Imprint />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/tos" element={<TermsOfService />} />

                  <Route path="*" element={<NoPage />} />
              </Routes>


              <div id="notification_system">
                  <div className="notification_list"></div>
              </div>

              <div id="scrollToTop" onClick={scrollToTopOnClick}>
                  <FaAngleUp />
              </div>

              <CookieConsent>
                  We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content, to analyze our website traffic, and to understand where our visitors are coming from.
              </CookieConsent>
          </div>
          <Footer />
      </BrowserRouter>
  );
}

export default App;
