import './Team.css'
import {createNotification, sendGet, setTitle} from "../../utils";
import {
    FaArrowCircleRight,
    FaCaretDown,
    FaCaretUp,
    FaDiscord,
    FaFacebook,
    FaInstagram, FaLink, FaTelegram,
    FaTwitter, FaWhatsapp
} from "react-icons/fa";
import {useEffect, useState} from "react";

const Team = ({ title }) => {
    setTitle(title);

    const [selectedProject, SetDropdownProject] = useState("CriseStudios");
    const [data, SetData] = useState({});

    const copyToClipboard = (text = '') => {
        navigator.clipboard.writeText(text);
        createNotification("i", "Copied to clipboard: "+text, 3);
    }

    useEffect(() => {
        const f = async () => {
            let res = await sendGet("crisestudios/teams");
            if(res.status === "ok") SetData(res.data);
        }
        f();
    }, []);

    return (
        <div id="team-container">
            <div className="team_container">
                {Object.keys(data).length > 0 ? (
                    <>
                        <div className="title">Meet our Team</div>
                        <div className="dropdown">
                            <span>Project</span>
                            <FaArrowCircleRight />
                            <div className="select">
                                <select onChange={(e) => {SetDropdownProject(e.target.value)}}>
                                    {Object.keys(data).map((k, i) => {
                                        return <option key={i} value={k}>{k}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="spacer" />
                        <div className="description">
                            Here you can get to know the different teams of our projects and find out a little about them
                        </div>

                        <div className="list">
                            {data[selectedProject].map((u, i) => {
                                return <div key={i} className="item">
                                    <div className="top">
                                        <img src={u.image} alt="" draggable="false" />
                                        <div className="links">
                                            {u.links.map((link, i2) => {
                                                let l = null;
                                                let url = new URL(link);
                                                switch (url.hostname) {
                                                    case "discord":
                                                        l = <>
                                                            <div className={"popup popup" + (i2 + 1)}>
                                                                <div className="arrow_background">
                                                                    <FaCaretDown />
                                                                </div>
                                                                <a onClick={() => {copyToClipboard("@"+(url.pathname.replace("/", "")));}}><span>@{url.pathname.replace("/", "")}</span></a>
                                                            </div>
                                                            <FaDiscord />
                                                        </>;
                                                        break;
                                                    case "twitter.com":
                                                        l = <>
                                                            <div className={"popup popup" + (i2 + 1)}>
                                                                <div className="arrow_background">
                                                                    <FaCaretDown />
                                                                </div>
                                                                <a href={link} target="_blank" rel="noreferrer"><span>@{url.pathname.replace("/", "")}</span></a>
                                                            </div>
                                                            <FaTwitter />
                                                        </>;
                                                        break;
                                                    case "instagram.com":
                                                        l = <>
                                                            <div className={"popup popup" + (i2 + 1)}>
                                                                <div className="arrow_background">
                                                                    <FaCaretDown />
                                                                </div>
                                                                <a href={link} target="_blank" rel="noreferrer"><span>@{url.pathname.replace("/", "")}</span></a>
                                                            </div>
                                                            <FaInstagram />
                                                        </>;
                                                        break;
                                                    case "facebook.com":
                                                        l = <>
                                                            <div className={"popup popup" + (i2 + 1)}>
                                                                <div className="arrow_background">
                                                                    <FaCaretDown />
                                                                </div>
                                                                <a href={link} target="_blank" rel="noreferrer"><span>@{url.pathname.replace("/", "")}</span></a>
                                                            </div>
                                                            <FaFacebook />
                                                        </>;
                                                        break;
                                                    case "telegram.com":
                                                        l = <>
                                                            <div className={"popup popup" + (i2 + 1)}>
                                                                <div className="arrow_background">
                                                                    <FaCaretDown />
                                                                </div>
                                                                <a href={link} target="_blank" rel="noreferrer"><span>@{url.pathname.replace("/", "")}</span></a>
                                                            </div>
                                                            <FaTelegram />
                                                        </>;
                                                        break;
                                                    case "whatsapp.com":
                                                        l = <>
                                                            <div className={"popup popup" + (i2 + 1)}>
                                                                <div className="arrow_background">
                                                                    <FaCaretDown />
                                                                </div>
                                                                <a href={link} target="_blank" rel="noreferrer"><span>@{url.pathname.replace("/", "")}</span></a>
                                                            </div>
                                                            <FaWhatsapp />
                                                        </>;
                                                        break;

                                                    default:
                                                        l = <>
                                                            <div className={"popup popup"+(i2+1)}>
                                                                <div className="arrow_background">
                                                                    <FaCaretDown />
                                                                </div>
                                                                <a onClick={() => {copyToClipboard("@VulpisNix#0001");}}><span>{url.host}</span></a>
                                                            </div>
                                                            <FaLink />
                                                        </>;
                                                        break;
                                                }
                                                return <div key={i2} className="link">
                                                    {l}
                                                </div>;
                                            })}
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <div className="item_name">{u.displayName}</div>
                                        <div className="item_title">{u.title}</div>
                                        <div className="item_description">{u.description}</div>
                                        <div className="item_date">Staff since: {u.staffSince}</div>
                                    </div>
                                </div>;
                            })}
                        </div>
                    </>
                ) : (<>
                    <div className="error">Something went wrong</div>
                </>)}
            </div>
        </div>
    );
}

Team.defaultProps = {
    title: 'Team'
}

export default Team;