import './Jobs.css'
import {setTitle} from "../../utils";


const Jobs = ({ title }) => {
    setTitle(title);

    return <></>;
}

Jobs.defaultProps = {
    title: 'Jobs'
}

export default Jobs;