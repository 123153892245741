import './Service.css'
import {setTitle} from "../../utils";

import logo from '../../images/logos/logo_square_v1_blurred.png';

const Imprint = ({ title }) => {
    setTitle(title);
    return (
        <div className="container_bgimage" style={{backgroundImage: 'url('+logo+')'}}>
            <div className='service-container imprint'>
                <h1>Imprint</h1>
                <h2>According to §5 TMG</h2>

                <div className="divider">
                    <div className="left"></div>
                    <div className="center"></div>
                    <div className="right"></div>
                </div>

                <p>
                    Philipp Schott
                    <br />
                    Haegestraße 29
                    <br />
                    33330 Gütersloh
                </p>
                <p>
                    <strong>Represented by: </strong><br />
                    Philipp Schott<br />
                </p>
                <p><strong>Contact:</strong> <br />
                Phone: 0176-83055655<br />
                E-Mail: <a href='mailto:contact@crisestudios.com'>contact@crisestudios.com</a></p>
                {/*<p>
                    <strong>Tax ID: </strong>
                    <br />
                    Sales tax identification number according to §27a sales tax law: xxx.
                </p>*/}
                <p>
                    <strong>Disclaimer: </strong>
                    <br /><br />
                    <strong>Liability for content</strong>
                    <br /><br />
                    The contents of our pages were created with great care. For correctness, completeness
                    However, we cannot guarantee that the content is up-to-date. As a service provider, we are in accordance with
                    § 7 Abs.1 TMG responsible for own content on these pages according to the general laws. To §§
                    8 to 10 TMG, however, as a service provider we are not obliged to transmit or store
                    monitor third-party information or investigate circumstances pointing to illegal activity
                    Clues. Obligations to remove or block the use of information under the
                    general laws remain unaffected. However, any liability in this regard is only effective from
                    time of knowledge of a specific infringement. Upon notification of corresponding
                    We will remove this content immediately if there is a violation of the law.
                    <br /><br />
                    <strong>Liability for links</strong>
                    <br /><br />
                    Our offer contains links to external third-party websites, the content of which we have no influence on.
                    Therefore we cannot assume any liability for this external content. For the content of
                    linked pages is always the responsibility of the respective provider or operator of the pages. the
                    linked pages were checked for possible legal violations at the time of linking.
                    Illegal content was not recognizable at the time of linking. A permanent content
                    However, checking the linked pages is not possible without concrete indications of an infringement
                    reasonable. As soon as we become aware of legal violations, we will remove such links immediately
                    remove.
                    <br /><br />
                    <strong>Copyright</strong>
                    <br /><br />
                    The content and works on these pages created by the site operators are subject to the German
                    Copyright. The duplication, processing, distribution and any kind of exploitation outside of the
                    Limits of copyright require the written consent of the respective author or creator.
                    Downloads and copies of this site are only permitted for private, non-commercial use.
                    Insofar as the content on this site was not created by the operator, the copyrights
                    third party observed. In particular contents of third parties are marked as such. You should anyway
                    If you become aware of a copyright infringement, we ask that you inform us accordingly. at
                    As soon as we become aware of legal violations, we will remove such content immediately
                    remove.
                    <br /><br />
                    <strong>Privacy</strong>
                    <br /><br />
                    Our website can usually be used without providing any personal data. So far up
                    personal data (e.g. name, address or e-mail addresses) is collected on our website
                    are, as far as possible, always on a voluntary basis. This data will be without your
                    express consent not passed on to third parties.
                    <br />
                    We would like to point out that data transmission on the Internet (e.g. when communicating by e-mail)
                    may have security vulnerabilities. A complete protection of the data against access by third parties
                    not possible.
                    <br />
                    The use of contact data published as part of the imprint obligation by third parties
                    Sending of unsolicited advertising and information material is hereby
                    expressly contradicted. The site operators expressly reserve the right to take legal action
                    case of unsolicited sending of advertising information, such as spam e-mails.
                </p>
                <br />


                <p><strong>Last updated:</strong> 14.07.2023</p>
            </div>
        </div>
    );
}

Imprint.defaultProps = {
    title: 'Imprint'
}

export default Imprint;