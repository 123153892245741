import './Profile.css';
import {setTitle} from "../../utils";
import ProfileNavbar from "../../components/profile/profile_navbar/ProfileNavbar";
import ProfileContent from "../../components/profile/profile_content/ProfileContent";
import {useState} from "react";

const Profile = () => {
    setTitle('Profile');
    let hash = document.location.hash;
    const [ProfilePage, SetProfilePage] = useState(hash === "" ? "#" : hash.toLowerCase());


    return (
        <div id="wrapper-profile">
            <ProfileNavbar ProfilePage={ProfilePage} SetProfilePage={SetProfilePage} />
            <ProfileContent ProfilePage={ProfilePage} />
        </div>
    );
}

Profile.defaultProps = {}

export default Profile;