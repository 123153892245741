import {FaBell} from "react-icons/fa6";
import Slider from "../../slider/Slider";
import {useEffect, useState} from "react";
import {createNotification, sendGet, sendUpdate} from "../../../utils";

const ProfileContent_Notification = () => {

    const PageTitle = "Notification";
    const PageSubTitle = "What type of email notifications would you like to receive? Here, you have the freedom to choose the notifications and information you'd like to receive from CriseStudios.";
    const PageIcon = <FaBell/>;

    const [Data, SetData] = useState(null);

    useEffect(() => {
        const f = async () => {
            let res = await sendGet("crisestudios/profile/get/notification");
            if(res.status === "ok") SetData(res.data);
            else {
                if(Data !== null) SetData(null);
            }
        }
        f();
    }, []);

    const OnSliderToggled = async (slider, checked) => {
        let sliderName = slider.dataset.name;
        if(sliderName === "") return;
        let sliderType = sliderName.split("#")[1];

        let res = await sendUpdate("crisestudios/profile/update/notification", {
            slider: sliderType,
            value: checked
        });

        if(res.status === "ok") createNotification("+", "Updated Notification Settings", 2);
        else createNotification("!", res.message, 4);
    }


    const NotificationSlider = (IsToggled, name = '', text = '') => {
        return <div className="slider_box">
            <Slider
                toggled={IsToggled}
                OnToggle={OnSliderToggled}
                dataName={"slider#"+name}
            />
            <div className="text">
                {text}
            </div>
        </div>;
    }

    return <>
        <div className="header">
            <div className="icon">
                {PageIcon}
            </div>
            <div className="titles">
                <div className="title">{PageTitle}</div>
                <div className="sub-title">{PageSubTitle}</div>
            </div>
        </div>

        {Data !== null && (
            <>
                {NotificationSlider(Data.newsletter.value, "newsletter", "Activate this option if you'd like to receive our latest newsletter.")}
                {NotificationSlider(Data.emailNotifications.value, "emailNotifications", "Activate this option if you'd like to receive security emails regarding your account.")}
            </>
        )}

    </>;
}

ProfileContent_Notification.props = {};
export default ProfileContent_Notification;