import './ImageCarousel.css'
import {useEffect, useState} from "react";
import {getAPIImage} from "../../utils";

const ImageCarousel = ({delay, data}) => {
    const [show, SetShow] = useState(0);
    let tout = null;
    let interval = null;
    let nextSlide = 0;

    const autoSlide = () => {
        if(data === null) return;
        if (interval != null) {
            clearInterval(interval);
            interval = null;
        }
        if (tout != null) {
            clearTimeout(tout);
            tout = null;
        }

        nextSlide++;
        if (nextSlide >= data.length) nextSlide = 0;
        SetShow(nextSlide);

        interval = setInterval(autoSlide, delay * 1000);
    }

    const slide = (i) => {
        if(data === null) return;
        if (interval != null) {
            clearInterval(interval);
            interval = null;
        }
        if (tout != null) {
            clearTimeout(tout);
            tout = null;
        }

        SetShow(i);

        if (data.length > 1) {
            tout = setTimeout(() => {
                interval = setInterval(autoSlide, delay * 1000);
            }, 20 * 1000);
        }
    }

    useEffect(() => {
        if(data === null) return;
        if (data.length > 1) interval = setTimeout(autoSlide, delay * 1000);
    }, []);


    return (
        <>
            {data !== null ? (
                <div id="image_carousel">
                    <div className="image_carousel_container" style={{width: (data.length + 1) + " * 100%"}}>

                        {data.map((d, i) => {
                            return <div key={i} className="item" style={{left: "calc(" + (i - show) + " * 100%)"}}>
                                <div className="media_container">
                                    {d.media.isImage ? (<img src={getAPIImage("/crisestudios/news/"+d.media.file)} alt="" draggable="false"/>) : (
                                        <video></video>
                                    )}
                                </div>
                                <div className="text_container">
                                    {d.button !== "" && (<a href={d.button}>
                                        <button className="more_btn">Read More</button>
                                    </a>)}
                                    <div className="text">
                                        <b>{d.title}</b>
                                        <br/>
                                        {d.text}
                                    </div>
                                </div>
                            </div>;
                        })}

                    </div>

                    <div className="controls">
                        {data.length > 1 && data.map((d, i) => {
                            return <div key={i} className={"control " + (show === i ? "active" : "")} onClick={() => {
                                slide(i);
                            }}/>;
                        })}
                    </div>
                </div>
            ) : <></> }
        </>
    );
}

ImageCarousel.defaultProps = {
    delay: 30,
    data: [
        {
            media: '',
            name: 'Name 1',
            title: 'Title',
            description: 'Description',
            buttons: [
                {
                    show: true,
                    text: 'Button text',
                    link: '#'
                }
            ]
        }
    ]
}
export default ImageCarousel;