import './Footer.css'
import logo from '../../images/logos/logo_and_lettering.png'

import { FaInstagram, FaFacebook, FaDiscord, FaTwitter, FaGithub, FaWifi, FaTicketAlt, FaComments, FaMailBulk } from "react-icons/fa";
import {GetCurrentYear, LINKS} from "../../utils";

const Footer = () => {
    let fullHost = document.location.pathname;
    let hostSplit = fullHost.split('/');
    const host = '/'+(hostSplit.length > 1 ? hostSplit[1] : '');





    return (
        <div className="footer noselect">
            {host === "/login" || host === "/register" ? (
                <>
                    <div className="footer-content-2">
                        <div className="company-copyright">&copy;2019-{GetCurrentYear()} CriseStudios. All Rights Reserved</div>
                        <div className="legal">
                            <a href="/imprint">Imprint</a>
                            <a href="/privacy">Privacy Policy</a>
                            <a href="/tos">Terms Of Service</a>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="footer-content">
                        <div className="footer-section-horizontal social-section">
                            <a href={LINKS.instagram} target="_blank" rel="noreferrer">
                                <div className="icon-container">
                                    <div className="icon"><FaInstagram/></div>
                                    <div className="name">Instagram</div>
                                </div>
                            </a>
                            <a href={LINKS.facebook} target="_blank" rel="noreferrer">
                                <div className="icon-container">
                                    <div className="icon"><FaFacebook/></div>
                                    <div className="name">Facebook</div>
                                </div>
                            </a>
                            <a href={LINKS.discord} target="_blank" rel="noreferrer">
                                <div className="icon-container">
                                    <div className="icon"><FaDiscord/></div>
                                    <div className="name">Discord</div>
                                </div>
                            </a>
                            <a href={LINKS.twitter} target="_blank" rel="noreferrer">
                                <div className="icon-container">
                                    <div className="icon"><FaTwitter/></div>
                                    <div className="name">Twitter</div>
                                </div>
                            </a>
                            <a href={LINKS.github} target="_blank" rel="noreferrer">
                                <div className="icon-container">
                                    <div className="icon"><FaGithub/></div>
                                    <div className="name">Github</div>
                                </div>
                            </a>
                        </div>
                        <div className="footer-section-horizontal company-section">
                            <div className="company-logo">
                                <img src={logo} alt="CriseStudios" draggable="false"/>
                            </div>
                            <div className="company-copyright">
                                &copy;2019-{GetCurrentYear()} CriseStudios. All Rights Reserved
                            </div>
                        </div>
                        <div className="footer-section-horizontal footer-links">
                            <div className="footer-section-vertical">
                                <div className="title">SUPPORT</div>
                                <a href="https://status.crisestudios.com">
                                    <div className="link">Server Status</div>
                                </a>
                                <a href="/support">
                                    <div className="link">Ticket Support</div>
                                </a>
                                <a href="/faq">
                                    <div className="link">FAQ</div>
                                </a>
                                <a href="mailto:contact@crisestudios.com">
                                    <div className="link">Mail</div>
                                </a>
                            </div>
                            <div className="footer-section-vertical">
                                <div className="title">COMPANY</div>
                                <a href="/jobs">
                                    <div className="link">Jobs</div>
                                </a>
                                <a href="/team">
                                    <div className="link">Team</div>
                                </a>
                                <a href="/services">
                                    <div className="link">Services</div>
                                </a>
                            </div>
                            <div className="footer-section-vertical">
                                <div className="title">LEGAL</div>
                                <a href="/imprint">
                                    <div className="link">Imprint</div>
                                </a>
                                <a href="/privacy">
                                    <div className="link">Privacy Policy</div>
                                </a>
                                <a href="/tos">
                                    <div className="link">Terms of Service (TOS)</div>
                                </a>
                                <a href="/privacy#cookies">
                                    <div className="link">Cookies Policy</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer-endspacer"></div>
                </>
            )}
        </div>
    );
}


/*
<div className="footer-content">

                <div className="footer-section">
                    <div className="company">
                        <div className="company-logo">
                            <img src={logo} alt="" draggable="false" />
                        </div>

                        <div className="flex-container">
                            <div className="flex-container-title">Support</div>
                            <div className="flex-container-content">
                                <div className="link">Contact us with <a href="/support#create">creating a ticket</a> or via mail <a href="mailto:support@crisestudios.net">support@crisestudios.com</a></div>
                            </div>
                        </div>
                    </div>

                    <div className="flex-container">
                        <div className="flex-container-title">About us</div>
                        <div className="flex-container-content">
                            <a href="/jobs"><div className="link">News</div></a>
                            <a href="/team"><div className="link">Team</div></a>
                            <a href="/forum/news"><div className="link">News</div></a>
                        </div>
                    </div>
                    <div className="flex-container">
                        <div className="flex-container-title">Media</div>
                        <div className="flex-container-content">
                            <a href="https://facebook.crisestudios.com"><div className="link">Facebook</div></a>
                            <a href="https://youtube.crisestudios.com"><div className="link">YouTube</div></a>
                            <a href="https://twitter.crisestudios.com"><div className="link">Twitter</div></a>
                            <a href="https://discord.crisestudios.com"><div className="link">Discord</div></a>
                        </div>
                    </div>
                    <div className="flex-container">
                        <div className="flex-container-title">Servers</div>
                        <div className="flex-container-content">
                            <a href="https://status.crisestudios.com"><div className="link">Server Status</div></a>
                            <a href="https://support.crisestudios.com/server_locations"><div className="link">Server Locations</div></a>
                            <a href="https://maintenance.crisestudios.com"><div className="link">Maintenance</div></a>
                        </div>
                    </div>
                    <div className="flex-container">
                        <div className="flex-container-title">Legal</div>
                        <div className="flex-container-content">
                            <a href="/privacy"><div className="link">Privacy Policy</div></a>
                            <a href="/tos"><div className="link">Terms of Service</div></a>
                            <a href="/imprint"><div className="link">Imprint</div></a>
                        </div>
                    </div>
                </div>
                <div className="footer-section footer-section-company">
                    <div className="company-copyright">
                        &copy; 2016-2023 CriseStudios. All Rights Reserved
                    </div>
                    <div className="links">
                        <a href="/imprint#privacy"><div className="link">Privacy Policy</div></a>
                        <div className="spacer"> | </div>
                        <a href="/tos"><div className="link">Terms Of Service</div></a>
                    </div>
                </div>

            </div>
*/

Footer.defaultProps = {}

export default Footer;