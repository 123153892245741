import './StyledInput2.css';

const StyledInput2 = ({id = '', className = '', icon, inputType = "text", placeholder = ''}) => {
    return <div id={"styledInput2_"+id} className={"styledInput2" + className}>
        <div className="icon">
            {icon}
        </div>
        <input id={id} type={inputType} placeholder={placeholder} />
    </div>
}

export default StyledInput2;