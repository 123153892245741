import './Projects.css'
import {getAPIImage, sendGet, sendPost, setTitle} from "../../utils";
import {useEffect, useRef, useState} from "react";

import {FaExternalLinkAlt} from "react-icons/fa";
import NoProjectImageFound from '../../images/no_project_image.png';

const Projects = ({title}) => {
    setTitle(title);

    const projectTypes = ["All", "Web", "App", "Game", "Web Game", "Software"];

    const [isLoading, setIsLoading] = useState(true);
    let projects = useRef([
        {
            "type": "Template",
            "name": "Template",
            "backgroundImage": NoProjectImageFound,
            "link": "/projects/view/?project=UUID"
        }]);
    const [projectsToShow, setProjectsToShow] = useState(projects.current);
    let skeletons = [];

    for (let i = 0; i < 3; i++) {
        let len = Math.random();
        if (len < 0.5) len = 0.5;
        len = len * 300;
        skeletons.push(len);
    }


    useEffect(() => {
        sendGet("crisestudios/projects")
            .then(response => {
                if (response.status === "ok") {
                    let proj = [];

                    response.data.map((data, index) => {
                        let type = data.type;
                        type = type.substring(0, 1).toUpperCase() + type.substring(1, type.length);
                        if (type.includes("-")) {
                            let start = type.indexOf("-");
                            let first = type.substring(0, start + 1);
                            let letter = type.substring(start + 1, start + 2).toUpperCase();
                            let end = type.substring(start + 2, type.length);
                            type = first + letter + end;
                        }

                        proj.push({
                            "type": type,
                            "name": data.name,
                            "backgroundImage": getAPIImage(data.thumbnail),
                            "link": "/projects/view/?project=" + data.uuid
                        });

                        return null;
                    });
                    projects.current = proj;

                    showCategory("all");

                    setTimeout(() => {
                        setIsLoading(false);
                    }, 250);
                }
            })
            .catch(err => {
                console.error(err);
            });
    }, [setProjectsToShow, setIsLoading]);


    const showCategory = (category = '') => {
        let categoryButtons = document.querySelectorAll(".project-type-selection-btn");
        let categoryButton = document.querySelectorAll("[datatype='" + category.toLowerCase().replaceAll(" ", "-") + "']")[0];
        let found = [];
        if (category === "all") {
            found = projects.current;
        } else {
            for (let proj of projects.current) {
                if (proj.type.toLowerCase().replaceAll(" ", "-") === category) {
                    found.push(proj);
                }
            }
        }
        setProjectsToShow(found);

        for (let btn of categoryButtons) {
            btn.classList.remove("selected");
        }
        categoryButton.classList.add("selected");
    }


    return (
        <div className="projects-container">
            <div className="section section-header">
                <div className="title">Our Projects</div>
                <div className="description">
                    Explore our projects and feel free to share your honest opinions. We believe in the power of
                    collaboration, and your input is invaluable to us
                </div>
            </div>
            <div className="section section-type-selection noselect">
                {
                    projectTypes.map((type, index) => {
                        return <div className="project-type-selection-btn" key={index}
                                    datatype={type.toLowerCase().replaceAll(" ", "-")} onClick={() => {
                            showCategory(type.toLowerCase().replaceAll(" ", "-"));
                        }}>
                            {type}
                        </div>;
                    })
                }
            </div>
            <div className="section projects-listing">
                {
                    isLoading ? (
                            skeletons.map((s, index) => {

                                return <div className="project-card" key={index}>
                                    <div className="background-image skeleton"></div>
                                    <div className="info-container">
                                        <div className="type">
                                            <div className="skeleton skeleton-text" style={{width: (s + "px")}}></div>
                                            <div className="skeleton skeleton-text"></div>
                                        </div>
                                        <div className="other">
                                            <div className="name">
                                                <div className="skeleton skeleton-text"
                                                     style={{width: ((s + (Math.random() < 0.5 ? -20 : 40)) + "px")}}></div>
                                                <div className="skeleton skeleton-text"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>;
                            })
                        ) :
                        (
                            projectsToShow.map((project, index) => {
                                return <a href={project.link} key={index}>
                                    <div className="project-card">
                                        <div className="background-image"><img
                                            src={project.backgroundImage !== "" ? project.backgroundImage : NoProjectImageFound}
                                            alt="" draggable="false"/></div>
                                        <div className="type">{project.type}</div>
                                        <div className="info-container">
                                            <div className="other">
                                                <div className="name">{project.name}</div>
                                                <div className="open"><FaExternalLinkAlt/></div>
                                                <div className="short-description">{project.shortDescription}</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>;
                            }))
                }
            </div>
        </div>
    );
}

Projects.defaultProps = {
    title: 'Projects'
}

export default Projects;