import './NewsView.css';
import {useParams} from "react-router";
import {useEffect, useState} from "react";
import {getAPIImage, sendGet, setTitle} from "../../utils";
import htmlParser from 'html-react-parser';

const NewsView = () => {
    let {newsId} = useParams();
    const [Data, SetData] = useState(null);

    useEffect(() => {
        const f = async () => {
            let res = await sendGet("crisestudios/news/get/"+newsId);
            if(res.status === "ok") {
                SetData(res.data);

                setTimeout(() => {
                    setTitle(res.data.title + " • " + res.data.project.shortName + " - News")
                }, 50);
            }
            else document.location = "/news";
        }
        f();
    }, []);

    return (
        <div id="wrapper-news-view">
            <div className="background-bars">
                <div className="bar1">
                    <div className="bar2"/>
                </div>
            </div>
            <div className="news-header">
                <a href="/news">
                    <div className="banner">Recent Updates & News</div>
                </a>
                <div className="banner2">
                <div>
                        <div/>
                        <div/>
                    </div>
                    <span>{Data !== null && Data.project.shortName}</span>
                    <div>
                        <div/>
                        <div/>
                    </div>
                </div>
            </div>
            <div className="news-content">
                <div className="news-content-header">
                    {Data !== null && (<img src={getAPIImage("crisestudios/news/"+Data.bannerImage)} alt="" draggable="false"/>)}
                    <div className="date">
                        <span>{Data !== null && (Data.published.date.split(" ")[2])}</span>
                        <span>{Data !== null && (Data.published.date.split(" ")[1])}</span>
                    </div>
                </div>
                <div className="news-content_post-header">
                    <div className="creator">
                        by <span>{Data !== null && (Data.creator.username)}</span>
                    </div>
                    <div className="post-title">
                        <div/>
                        {Data !== null && (Data.title)}
                    </div>
                    <div className="short-description">
                        {Data !== null && (Data.shortDescription)}
                    </div>
                </div>
                <div className="spacer"/>

                {/* Below is modular content generated/controlled by the apis data */}


                {Data !== null && (
                    Data.content.map((c, i) => {
                        return (<div key={i}>
                            <div className="news-content-section">
                                <div className="news-content-section-title">
                                    <div/>
                                    {c.title}
                                </div>
                                {htmlParser(c.content)}
                            </div>
                            <div className="spacer"/>
                        </div>);
                    })
                )}


            </div>
        </div>
    );
}

NewsView.props = {}
export default NewsView;