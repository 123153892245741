import './UserNavbarDropdown.css';
import {FaSignOutAlt, FaUserCircle} from 'react-icons/fa';
import {useState} from "react";
import {onLogoutClick} from "../../../utils";
import {FaBell} from "react-icons/fa6";

const UserNavbarDropdown = ({username, image, isMobile}) => {
    const [dropdown, setDropdown] = useState(false);

    return (
        <div className={isMobile ? "user-dropdown-mobile" : "user-dropdown"}>
            <div className="user" onClick={() => setDropdown(!dropdown)}
                 style={isMobile && dropdown ? {marginTop: "15px"} : {}}>
                <div className="user-image">
                    {(image && image !== '') ? (
                        <img src={image} alt={username.split('')[0]} draggable="false"/>
                    ) : (
                        <>{username.split('')[0]}</>
                    )}
                </div>
                <div className="user-name">{username}</div>
            </div>
            {dropdown ? (
                <div className="dropdown">
                    <a href="/profile">
                        <div className="dropdown-item"><FaUserCircle/> Profile</div>
                    </a>
                    <a href="/profile#notification" onClick={() => {
                        if (document.location.href.includes('/profile')) {
                            setTimeout(() => {
                                document.location.reload();
                            }, 10)
                        }
                    }}>
                        <div className="dropdown-item"><FaBell/> Notifications</div>
                    </a>
                    <a>
                        <div className="dropdown-item dropdown-item-logout" onClick={onLogoutClick}>
                            <FaSignOutAlt/> Logout
                        </div>
                    </a>
                </div>
            ) : ''}
        </div>
    );
}

UserNavbarDropdown.defaultProps = {
    username: 'Username not loaded!',
    image: '',
    isMobile: false
}

export default UserNavbarDropdown;