import {useState} from "react";
import {clearStorage, createNotification, getQuery, LINKS, sendPost, setCookie, setTitle} from "../../../utils";
import {FaCircleArrowLeft, FaLock, FaPlus, FaUser} from "react-icons/fa6";
import StyledInput2 from "../../../components/styledinput2/StyledInput2";
import {FaDiscord, FaFacebook, FaGithub, FaSignInAlt, FaTwitter} from "react-icons/fa";

const Login = ({title}) => {
    setTitle(title);
    const [error, ShowError] = useState(null);
    const [loginBtnDisabled, SetLoginBtnDisabled] = useState(false);
    const [rememberMeToggle, SetRememberMeToggle] = useState(false);

    const _login = async () => {
        const loginId = document.getElementById('inputLoginId').value;
        const password = document.getElementById('inputPassword').value;

        if (loginId.length < 3) {
            if (loginId.length === 0) ShowError("Please enter a loginId");
            else ShowError("Your loginId is not long enough");
            return false;
        }
        if (password.length <= 0) {
            ShowError("Please enter a valid password");
            return false;
        }

        SetLoginBtnDisabled(true);
        let response = await sendPost("auth/login", {
            loginId: loginId,
            password: password,
            type: "web",
            keepLoggedIn: rememberMeToggle
        });

        if (response.status !== "ok") {
            ShowError(response.message);
            SetLoginBtnDisabled(false);
            return;
        }

        clearStorage();
        setCookie("auth", response.data.token, rememberMeToggle ? (28 * 24) : 24);
        setCookie("user", JSON.stringify(response.data.user), rememberMeToggle ? (28 * 24) : 24);

        createNotification("+", "Successfully logged in!", 3);

        let redirectQuery = getQuery("re");
        document.location = redirectQuery === "" ? '/profile' : redirectQuery;
    }

    return <div className="container">
        <div className="inner-container auth-container">
            <img src="/images/logos/logo_and_lettering.png" alt="" draggable="false"/>
            <div className="auth-box auth-box-login">
                <div className="content content-login">
                    <div className="content-login-left">
                        <div className="banner">Welcome back</div>
                        <div className="title">Login</div>
                        <div className="error-text">{error}</div>
                        <div className="inputs">
                            <StyledInput2
                                inputType="text"
                                icon={<FaUser/>}
                                id={"inputLoginId"}
                            />
                            <StyledInput2
                                inputType="password"
                                icon={<FaLock/>}
                                id={"inputPassword"}
                            />
                        </div>
                        <div className="spacer">
                            <div/>
                            <div/>
                            <div/>
                        </div>
                        <div className="other">
                            <div className="rememberMe">
                                <input type="checkbox" id={"checkboxRememberMe"} onChange={() => {
                                    SetRememberMeToggle(!rememberMeToggle)
                                }}/>
                                <span className="checkmark"></span>
                                <label htmlFor={"checkboxRememberMe"}>Remember Me</label>
                            </div>
                            <a href="/forgot-password">
                                <div className="forgotPassword">
                                    Forgot password?
                                </div>
                            </a>
                        </div>

                        <button className="btn-company btn-login"
                                disabled={loginBtnDisabled}
                                onClick={_login}>
                            <div/>
                            <FaSignInAlt/>
                            Login
                        </button>

                        <a href="/register">
                            <button className="btn-success btn-register">
                                <div/>
                                Register
                            </button>
                        </a>
                        <div className="socials">
                            <div/>
                            <div className="socials-content">
                                <a href={LINKS.discord} rel="noreferrer" target="_blank"><FaDiscord
                                    className="discord"/></a>
                                <a href={LINKS.twitter} rel="noreferrer" target="_blank"><FaTwitter
                                    className="twitter"/></a>
                                <a href={LINKS.facebook} rel="noreferrer" target="_blank"><FaFacebook
                                    className="facebook"/></a>
                                <a href={LINKS.github} rel="noreferrer" target="_blank"><FaGithub
                                    className="github"/></a>
                            </div>
                            <div/>
                        </div>
                    </div>
                    <div className="content-login-right">
                        <div className="banner">
                            Welcome to <span>CriseStudios</span>!
                            <br/>
                            If you haven't registered yet, please take a moment to create an account before proceeding
                            to login.
                        </div>
                        <div className="register_box">
                            <span><FaUser/><FaPlus/></span>
                            <a href="/register">
                                <button className="btn-company">Register</button>
                            </a>
                        </div>
                        <div className="socials">
                        <div/>
                            <div className="socials-content">
                                <a href={LINKS.discord}     rel="noreferrer" target="_blank"><FaDiscord className="discord"/></a>
                                <a href={LINKS.twitter}     rel="noreferrer" target="_blank"><FaTwitter className="twitter"/></a>
                                <a href={LINKS.facebook}    rel="noreferrer" target="_blank"><FaFacebook className="facebook"/></a>
                                <a href={LINKS.github}      rel="noreferrer" target="_blank"><FaGithub className="github"/></a>
                            </div>
                            <div/>
                        </div>

                        <a href="/">
                            <div className="company-url">
                                www.crisestudios.com
                            </div>
                        </a>
                        <a href="/imprint">
                            <div className="legal">
                                Legal
                            </div>
                        </a>
                    </div>
                </div>
                <div className="footer">
                    <a href="/"><FaCircleArrowLeft/> Back to Homepage</a>
                </div>
            </div>
        </div>
    </div>;
}

Login.defaultProps = {
    title: 'Login'
}

export default Login;


/*<button className="btn-primary" onClick={_login} disabled={loginBtnDisabled}>Login <FaArrowRight/></button>*/
