import './StyledInput.css';

const StyledInput = ({id, className = '', label = '', isRequired = false, inputType = "text", placeholder = '', value = ''}) => {
    return <div id={"styledInput_"+id} className={"styledInput " + className}>
        <div className="label">
            <div />
            <span>{label}{isRequired ? (<span>*</span>) : ""}</span>
        </div>
        <input id={id} type={inputType} placeholder={placeholder} defaultValue={value} />
    </div>
}

export default StyledInput;