import './Navbar.css'
import {fetchUserData, getAPIImage, getCookie} from '../../utils';
import UserNavbarDropdown from "./usernavbardropdown/UserNavbarDropdown";

import lettering from '../../images/logos/logo_and_lettering.png';
import {useEffect, useState} from "react";

const Navbar = ({ items }) => {
    const authCookie = getCookie('auth');

    const [isLoggedIn, setIsLoggedIn] = useState(authCookie !== undefined);
    const [username, setUsername] = useState('');
    const [userImage, setUserImage] = useState('');


    useEffect(() => {
        if(authCookie !== undefined) {
            let user = getCookie('user');
            if(user !== undefined) {
                user = JSON.parse(user);

                fetchUserData(user.uuid).then(response => {
                    if(response.status === 'ok') {
                        const data = response.data;
                        const usr = data.user;
                        const profile = data.profile;
                        setUsername(usr.username);
                        setIsLoggedIn(true);
                        setUserImage(getAPIImage("crisestudios/user_images/"+profile.picture));
                    }
                });
            }
        }
    }, [setUsername, setIsLoggedIn, setUserImage, authCookie]);

    const [showMobileNav, setShowMobileNav] = useState(false);

    const toggleMobileNav = () => {
        setShowMobileNav(!showMobileNav);
    }

    return (
        <>
            <div id="navbar" className="noselect">
                <a href="/"><div className="navbar-logo"><img src={lettering} alt="CriseStudios" draggable="false" /></div></a>
                <div className="navbar-items-wrapper">
                    {items.map((item, index) => (
                        <a href={item.link} key={index}>
                            <div className={item.highlighted ? "navbar-item highlighted" : "navbar-item"}>
                                {item.text}
                            </div>
                        </a>
                    ))}
                </div>
                {isLoggedIn ? (
                    <div className="user-section user-section-logged_in">
                        <UserNavbarDropdown username={username} image={userImage} />
                    </div>
                ) : (
                    <div className="user-section">
                        <a href="/login"><div className="nav-btn nav-btn-left">
                            <div className="nav-btn-content">
                                Sign in
                            </div></div> </a>
                        <a href="/register"><div className="nav-btn nav-btn-right">
                            <div className="nav-btn-content">
                                Sign up
                            </div>
                        </div></a>
                    </div>
                )}
            </div>

            <div id="mobile-navbar" className="noselect">
                <a href="/"><div className="navbar-logo"><img src={lettering} alt="CriseStudios" draggable="false" /></div></a>
                {showMobileNav && (
                    <div className="navbar-items-wrapper">
                        {isLoggedIn ? (
                            <div className="user-section">
                                <UserNavbarDropdown username={username} image={userImage} isMobile={true} />
                            </div>
                        ) : (
                        <div className="user-section">
                            <a href="/login"><button className="btn-outlined-whiteBlue">Sign in</button></a>
                            <span>/</span>
                            <a href="/register"><button className="btn-outlined-whiteBlue">Sign up</button></a>
                        </div>
                        )}

                        {items.map((item, index) => (<a href={item.link} key={index}><div className={item.highlighted ? "navbar-item highlighted" : "navbar-item"}>{item.text}</div></a>))}
                    </div>
                )}
                <div className={showMobileNav ? "toggle close" : "toggle"} onClick={toggleMobileNav}>
                    <div className="bars">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>

            </div>
        </>
    );
}

Navbar.defaultProps = {
    items: [{
        text: "Untitled",
        link: "/"
    }]
}

export default Navbar;