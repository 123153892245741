import './VerifyEmail.css'
import {getQuery, isValidEmail, isValidMobileNumber, sendPost, setTitle} from "../../../utils";
import lettering from '../../../images/logos/logo_and_lettering.png';
import backgroundImage from '../../../images/backgrounds/f1f9dfd3-577d-4510-8343-ed91b3be01b0.png';
import {useEffect, useState} from "react";
import StyledInput from "../../../components/styledinput/StyledInput";
import {FaArrowRight, FaCheckDouble, FaCircleArrowLeft, FaInbox} from "react-icons/fa6";
import SixDigitCodeInput from "../../../components/sixDigitCodeInput/sixDigitCodeInput";
import {FaQuestionCircle} from "react-icons/fa";

const VerifyEmail = ({title}) => {
    setTitle(title);
    const code = getQuery('code');
    const [step, SetStep] = useState(1);
    const [error, ShowError] = useState(null);

    const toggleCodeInputs = (disabled = false) => {
        for (let i = 1; i < 7; i++) document.querySelector(".sixDigitCodeInput" + i).toggleAttribute("disabled", disabled);
    }
    const codeChanged = (code = '') => {
        if(code.length === 6) {
            toggleCodeInputs(true);
            //TODO: Check code

            const f = async () => {
                let res = await sendPost("auth/verify", {
                    code
                });

                if(res.status === "ok") {
                    SetStep(2);
                    return;
                }
                ShowError(res.message);
                toggleCodeInputs(false);
            }
            f();
        }
    }

    return (
        <div className="container">
            <div className="inner-container auth-container">
                <img src="/images/logos/logo_and_lettering.png" alt="" draggable="false"/>
                <div className="page-title">Account Verification</div>
                <div className="auth-box">
                    <div className="header">
                        <div className={"step-fill step-fill-" + (step + 1)}
                             style={{width: "calc((100% / 3) * " + (step + 1) + ")"}}/>

                        <div className="step">
                            <div className="circle">1</div>
                            <span>Account creation</span>
                        </div>
                        <div className="step">
                            <div className="circle">2</div>
                            <span>Account confirmation</span>
                        </div>
                        <div className="step">
                            <div className="circle">3</div>
                            <span>Ready to go</span>
                        </div>
                    </div>
                    <div className={"content content-step" + (step + 1)}>
                        {step === 1 && (
                            <>
                                <div className="title">Account confirmation</div>
                                <br/>
                                <div className="sub-title">
                                    Enter the 6-digit code below that we sent to your email.
                                    <br/>If necessary, also check your spam folder.
                                </div>
                                <br/><br/>
                                <div className="codeInput">
                                    <SixDigitCodeInput value={code} onChange={codeChanged}/>
                                </div>
                                <br/>
                                <br/>
                                {error !== null && (
                                    <>
                                        <span className="error-text">{error}</span>
                                        <br/><br/>
                                    </>
                                )}
                                <br/><br/><br/><br/>
                            </>
                        )}
                        {step === 2 && (
                            <>
                                <div className="title">Welcome to CriseStudios <FaCheckDouble color={"#5DF16C"}/></div>
                                <br/>
                                <div className="sub-title">
                                    Welcome to our community! We are pleased that you have registered and become part of
                                    our
                                    online family.
                                </div>
                                <br/><br/>

                                <div className="list">
                                    <div className="item">
                                        <div className="title">Account Settings</div>
                                        <a href="/account">
                                            <div className="i">User</div>
                                        </a>
                                        <a href="/account#connections">
                                            <div className="i">Connections</div>
                                        </a>
                                        <a href="account#notifications">
                                            <div className="i">Notifications</div>
                                        </a>
                                    </div>

                                    <div className="item">
                                        <div className="title">Products</div>
                                        <a href="/products?type=game">
                                            <div className="i">Games</div>
                                        </a>
                                        <a href="/products?type=web">
                                            <div className="i">Websites</div>
                                        </a>
                                        <a href="/products?type=app">
                                            <div className="i">Apps</div>
                                        </a>
                                    </div>

                                    <div className="item">
                                        <div className="title">Company</div>
                                        <a href="/imprint">
                                            <div className="i">Imprint</div>
                                        </a>
                                        <a href="/privacy">
                                            <div className="i">Privacy Policy</div>
                                        </a>
                                        <a href="/tos">
                                            <div className="i">Terms Of Service</div>
                                        </a>
                                    </div>
                                </div>

                                <br/>

                                <div className="links">
                                    <a href="/news">News</a>
                                    <a href="/">Homepage</a>
                                    <a href="/support">Support</a>
                                </div>

                            </>
                        )}

                        <button className="btn-outlined-success btn_needHelp">Need help <FaQuestionCircle/></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

VerifyEmail.defaultProps = {
    title: 'Email verification'
}

export default VerifyEmail;