import './ForgotPassword.css'
import {getCookie, setTitle, sendPost, onLogoutClick} from '../../../utils';


const ForgotPassword = ({ title }) => {
    setTitle(title);
    const isAuthKey = getCookie('auth');
    const showMessage = (title, message) => {
        const loginFormRight = document.querySelector('.forgot_password-form-right').getElementsByClassName('inner')[0];
        loginFormRight.innerHTML =
            `
            <h1>`+title+`</h1>
            <p>`+message+`</p>
            `;
    }

    const resetPassword = async () => {
        const resetButton = document.querySelector('.btn-login');

        const loginId = document.getElementById('inputLoginId').value;
        const email = document.getElementById('inputEmail').value;

        if(loginId.length < 3 || email.length < 3) return;

        resetButton.setAttribute('disabled', '');
        resetButton.classList.add('btn-loading');

        let result = await sendPost('auth/forgot-password', {
            loginId: loginId,
            email: email,
            type: 'crisestudios_web'
        })

        if(result.status === 'error') {
            console.error('Login error: '+result.message);

            showMessage('Login error', result.message);
            resetButton.removeAttribute('disabled');
            resetButton.classList.remove('btn-loading');
        }
        else
        if(result.status === 'ok') {
            resetButton.removeAttribute('disabled');
            resetButton.classList.remove('btn-loading');
        }
    }

    let currentDescription = '';
    const resetDescription = () => {
        if(currentDescription === 'RESET') return;
        currentDescription = 'RESET';
        showMessage('CriseStudios', ``)
    }

    setTimeout(resetDescription, 50);
    return (
        <div className="container">
            <div className="inner-container forgot_password-container">
                <div className="forgot_password-form">
                    <div className="forgot_password-form-left">
                        {isAuthKey ? (
                            <div className="already-logged-in">
                                <div className="message">
                                    You are logged in!
                                    <br />
                                    Please log out to see this page.</div>
                                <button onClick={onLogoutClick} className="btn-danger">Logout</button>
                            </div>
                        ) : (
                          <>
                              <div className="title">Forgot Password</div>
                              <div className="text">
                                  Please enter your CriseId and email address to reset your password.
                              </div>
                              <form onSubmit={(e) => {e.preventDefault()}}>
                                  <input type="text" placeholder="CriseID" id="inputLoginId" required />
                                  <br />
                                  <input type="email" placeholder="Email" id="inputEmail" required />
                                  <br />
                                  <br />
                                  <button type="submit" className="btn-login btn-primary btn-centered btn-fontBold btn-rounded" onClick={resetPassword}>Reset</button>
                              </form>
                          </>
                        )}

                    </div>
                    <div className="forgot_password-form-right">
                        <div className="inner"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ForgotPassword.defaultProps = {
    title: 'Forgot Password'
}

export default ForgotPassword;