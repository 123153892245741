import {
    clearStorage,
    getAuth,
    getCookie,
    isValidEmail,
    isValidMobileNumber,
    sendGet,
    sendPost, setCookie,
    setTitle
} from "../../../utils";
import {useState} from "react";
import StyledInput from "../../../components/styledinput/StyledInput";
import {FaQuestionCircle} from "react-icons/fa";
import {FaArrowRight, FaCheckDouble, FaCircleArrowLeft, FaInbox} from "react-icons/fa6";
import SixDigitCodeInput from "../../../components/sixDigitCodeInput/sixDigitCodeInput";

const Register = ({title}) => {
    setTitle(title);
    const CODE_TIMEOUT = 120;
    let authData = getAuth();
    const [step, SetStep] = useState(0);
    const [error, ShowError] = useState(null);

    const [CodeAttempts, SetCodeAttempts] = useState(0);
    const [verificationCodeSent, SetVerificationCodeSent] = useState(false);
    const [verificationCodeTime, SetVerificationCodeTime] = useState(CODE_TIMEOUT);
    let verificationCodeInterval = null;

    const toggleCreationInputs = (disabled = false) => {
        document.getElementById('inputUsername').toggleAttribute("disabled", disabled);
        document.getElementById('inputLoginId').toggleAttribute("disabled", disabled);
        document.getElementById('inputFirstname').toggleAttribute("disabled", disabled);
        document.getElementById('inputLastname').toggleAttribute("disabled", disabled);
        document.getElementById('inputEmail').toggleAttribute("disabled", disabled);
        document.getElementById('inputPhone').toggleAttribute("disabled", disabled);
        document.getElementById('inputPassword').toggleAttribute("disabled", disabled);
        document.getElementById('inputPasswordRepeat').toggleAttribute("disabled", disabled);
    }
    const toggleCodeInputs = (disabled = false) => {
        for (let i = 1; i < 7; i++) document.querySelector(".sixDigitCodeInput" + i).toggleAttribute("disabled", disabled);
    }

    const _register = async () => {
        toggleCreationInputs(false);
        const username = document.getElementById('inputUsername').value;
        const loginId = document.getElementById('inputLoginId').value;
        const firstname = document.getElementById('inputFirstname').value;
        const lastname = document.getElementById('inputLastname').value;
        const email = document.getElementById('inputEmail').value;
        const phone = document.getElementById('inputPhone').value;
        const password = document.getElementById('inputPassword').value;
        const passwordRepeat = document.getElementById('inputPasswordRepeat').value;


        if (username.length < 3) {
            if (username.length === 0) ShowError("Please enter a username");
            else ShowError("Your username is not long enough");
            return false;
        }
        if (loginId.length < 3) {
            if (loginId.length === 0) ShowError("Please enter a loginId");
            else ShowError("Your loginId is not long enough");
            return false;
        }
        if (loginId.length >= 3) {
            if (loginId.toLowerCase() === username.toLowerCase()) {
                ShowError("Your loginId can`t be the same as your username");
                return false;
            }
        }
        if (email.length === 0 || !isValidEmail(email)) {
            ShowError("Please enter a valid email");
            return false;
        }
        if (phone.length > 0) {
            if (!isValidMobileNumber(phone)) {
                ShowError("Please enter a valid phone number");
                return false;
            }
        }
        if (password.length === 0) {
            ShowError("Please enter a valid password");
            return false;
        }
        if (passwordRepeat.length === 0) {
            ShowError("You forgot to repeat your password.");
            return false;
        }
        if (password !== passwordRepeat) {
            ShowError("You passwords do not match!");
            return false;
        }


        toggleCreationInputs(true);
        let result = await sendPost('auth/register', {
            username,
            loginId,
            firstname,
            lastname,
            email,
            phone,
            password,
            passwordRepeat,
            type: "web"
        })

        if (result.status === 'ok') {
            if (error !== null) ShowError(null);

            clearStorage();
            setCookie("auth", result.data.token, 24);
            setCookie("user", JSON.stringify(result.data.user), 24);

            return true;
        }
        ShowError(result.message);
        toggleCreationInputs(false);
        return false;
    }
    const _verifyCode = async () => {
        toggleCodeInputs(false);
        document.getElementById("VerificationCodeButton").toggleAttribute("disabled", false);

        let code = "";
        for (let i = 1; i < 7; i++) code += document.querySelector(".sixDigitCodeInput" + i).value;
        if (code.length < 6) {
            ShowError("Please enter the verification code");
            return false;
        }

        toggleCodeInputs(true);

        document.getElementById("VerificationCodeButton").toggleAttribute("disabled", true);

        let res = await sendPost("auth/verify", {
            verifyCode: code
        });
        if (res.status === "ok") {
            //TODO: THE RESPONSE DATA IS THE AUTH KEY!!!!
            return true;
        } else {
            toggleCodeInputs(false);
            ShowError(res.message);
            document.getElementById("VerificationCodeButton").toggleAttribute("disabled", false);
            return false;
        }
    }

    const sendVerificationCode = async ({target}) => {
        if (verificationCodeSent) return;

        target.setAttribute("disabled", "");
        SetVerificationCodeSent(true);
        let _cur = CodeAttempts;
        SetCodeAttempts(_cur + 1);
        authData = getAuth();

        if (_cur > 0) {
            let res = await sendPost("auth/resend-verify", {
                auth: authData.token
            });
            if (res.status !== "ok") {
                toggleCodeInputs(false);
                ShowError(res.message);
                document.getElementById("VerificationCodeButton").toggleAttribute("disabled", false);
            }
        }

        let _tempTime = CODE_TIMEOUT;
        verificationCodeInterval = setInterval(() => {
            _tempTime -= 1;
            SetVerificationCodeTime(_tempTime);

            if (_tempTime <= 0) {
                clearInterval(verificationCodeInterval);
                verificationCodeInterval = null;
                target.removeAttribute("disabled");

                SetVerificationCodeSent(false);
                SetVerificationCodeTime(CODE_TIMEOUT + 1);
            }

        }, 1000);
    }


    const nextStepButton = () => {
        const _step = step;
        const _nextStep = _step + 1;

        const f = async () => {
            if (_step === 0) {
                let res = await _register();
                if (res === true) {
                    SetStep(_nextStep);
                    if (error !== null) ShowError(null);

                    setTimeout(() => {
                        const btn = document.getElementById("VerificationCodeButton");
                        sendVerificationCode({target: btn});
                    }, 1);
                }
            } else if (_step === 1) {
                let res = await _verifyCode();
                if (res === true) SetStep(_nextStep);
            }
        };
        f();
    }


    return <div className="container">
        <div className="inner-container auth-container">
            <img src="/images/logos/logo_and_lettering.png" alt="" draggable="false"/>
            <div className="page-title">Create an Account</div>
            <div className="auth-box">
                <div className="header">
                    <div className={"step-fill step-fill-" + (step + 1)}
                         style={{width: "calc((100% / 3) * " + (step + 1) + ")"}}/>

                    <div className="step">
                        <div className="circle">1</div>
                        <span>Account creation</span>
                    </div>
                    <div className="step">
                        <div className="circle">2</div>
                        <span>Account confirmation</span>
                    </div>
                    <div className="step">
                        <div className="circle">3</div>
                        <span>Ready to go</span>
                    </div>
                </div>
                <div className={"content content-step" + (step + 1)}>
                    {step === 0 && (
                        <>
                            <div className="title">Account creation</div>
                            <br/>
                            <div className="sub-title">
                                By creating a CriseStudios account you have access to all of our products and games and
                                become part of the community
                            </div>

                            <br/><br/><br/>

                            <div className="row">
                                <StyledInput
                                    label="Username"
                                    isRequired={true}
                                    id="inputUsername"
                                />
                                <StyledInput
                                    label="LoginId"
                                    isRequired={true}
                                    id="inputLoginId"
                                />
                            </div>
                            <br/><br/>
                            <div className="row">
                                <StyledInput
                                    label="First Name"
                                    id="inputFirstname"
                                />
                                <StyledInput
                                    label="Last Name"
                                    id="inputLastname"
                                />
                            </div>
                            <br/><br/>
                            <div className="row">
                                <StyledInput
                                    label="E-Mail"
                                    isRequired={true}
                                    inputType={"email"}
                                    id="inputEmail"
                                />
                                <StyledInput
                                    label="Mobilephone"
                                    inputType={"tel"}
                                    id="inputPhone"
                                />
                            </div>
                            <br/><br/>
                            <div className="row">
                                <StyledInput
                                    label="Password"
                                    isRequired={true}
                                    inputType={"password"}
                                    id="inputPassword"
                                />
                                <StyledInput
                                    label="Password confirmation"
                                    isRequired={true}
                                    inputType={"password"}
                                    id="inputPasswordRepeat"
                                />
                            </div>
                            <br/><br/>
                            {error !== null && (
                                <>
                                    <span className="error-text">{error}</span>
                                    <br/><br/>
                                </>
                            )}
                            <br/><br/><br/>
                        </>
                    )}
                    {step === 1 && (
                        <>
                            <div className="title">Account confirmation</div>
                            <br/>
                            <div className="sub-title">
                                Enter the 6-digit code below that we sent to your email.
                                <br/>If necessary, also check your spam folder.
                            </div>
                            <br/><br/>
                            <button id="VerificationCodeButton" className="btn-primary btn_sendCode"
                                    onClick={sendVerificationCode}>{CodeAttempts > 0 ? "Re-" : ""}Send Verification
                                Code <FaInbox/></button>
                            <br/><br/>

                            <div className="codeInput">
                                <SixDigitCodeInput/>
                            </div>
                            <br/>
                            {verificationCodeTime === (CODE_TIMEOUT+1) ?
                                (
                                    <span className="time">Your code has expired.</span>)
                                :
                                (
                                    <span className="time">Your code expires in {verificationCodeTime} seconds.</span>
                                )}

                            <br/><br/>
                            {error !== null && (
                                <>
                                    <span className="error-text">{error}</span>
                                    <br/><br/>
                                </>
                            )}
                            <br/><br/><br/><br/>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <div className="title">Welcome to CriseStudios <FaCheckDouble color={"#5DF16C"}/></div>
                            <br/>
                            <div className="sub-title">
                                Welcome to our community! We are pleased that you have registered and become part of our
                                online family.
                            </div>
                            <br/><br/>

                            <div className="list">
                                <div className="item">
                                    <div className="title">Account Settings</div>
                                    <a href="/account">
                                        <div className="i">User</div>
                                    </a>
                                    <a href="/account#connections">
                                        <div className="i">Connections</div>
                                    </a>
                                    <a href="account#notifications">
                                        <div className="i">Notifications</div>
                                    </a>
                                </div>

                                <div className="item">
                                    <div className="title">Products</div>
                                    <a href="/products?type=game">
                                        <div className="i">Games</div>
                                    </a>
                                    <a href="/products?type=web">
                                        <div className="i">Websites</div>
                                    </a>
                                    <a href="/products?type=app">
                                        <div className="i">Apps</div>
                                    </a>
                                </div>

                                <div className="item">
                                    <div className="title">Company</div>
                                    <a href="/imprint">
                                        <div className="i">Imprint</div>
                                    </a>
                                    <a href="/privacy">
                                        <div className="i">Privacy Policy</div>
                                    </a>
                                    <a href="/tos">
                                        <div className="i">Terms Of Service</div>
                                    </a>
                                </div>
                            </div>

                            <br/>

                            <div className="links">
                                <a href="/news">News</a>
                                <a href="/">Homepage</a>
                                <a href="/support">Support</a>
                            </div>

                        </>
                    )}

                    <button className="btn-outlined-success btn_needHelp">Need help <FaQuestionCircle/></button>
                </div>
                <div className="footer">
                    {step === 0 && (
                        <>
                            <a href="/"><FaCircleArrowLeft/> Back to Homepage</a>
                            <button className="btn-primary" onClick={nextStepButton}>Next step <FaArrowRight/></button>
                        </>
                    )}
                    {step === 1 && (
                        <>
                            <a href="/"><FaCircleArrowLeft/> Back to Homepage</a>
                            <button className="btn-primary" onClick={nextStepButton}>Next step <FaArrowRight/></button>
                        </>
                    )}
                </div>
            </div>
        </div>
    </div>;
}

Register.defaultProps = {
    title: 'Register'
}

export default Register;