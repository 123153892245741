import './NoPage.css'
import {setTitle} from "../../utils";

import pageNotFoundLogo from '../../images/404.png';
import {Link} from "react-router-dom";

const NoPage = ({ title }) => {
    setTitle(title);
    return (
        <div className="container">
            <div className="pageNotFound">
                <img src={pageNotFoundLogo} alt="" draggable="false" />
                <br /><br /><br />
                <Link to="/"><button className="btn-primary btn-centered">Back to Home</button></Link>
            </div>
        </div>
    );
}

NoPage.defaultProps = {
    title: 'Page not found'
}

export default NoPage;