import {FaCreditCard} from "react-icons/fa6";

const ProfileContent_PaymentMethods = () => {

    const PageTitle = "Payment Methods";
    const PageSubTitle = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam";
    const PageIcon = <FaCreditCard/>;


    return <>
        <div className="header">
            <div className="icon">
                {PageIcon}
            </div>
            <div className="titles">
                <div className="title">{PageTitle}</div>
                <div className="sub-title">{PageSubTitle}</div>
            </div>
        </div>
    </>;
}

ProfileContent_PaymentMethods.props = {};
export default ProfileContent_PaymentMethods;