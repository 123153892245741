import './ProfileNavbar.css';
import {
    FaBell, FaCamera,
    FaCircleMinus,
    FaCreditCard,
    FaHandshakeAngle,
    FaPerson, FaTrophy,
    FaUserLock
} from "react-icons/fa6";
import {FaCaretDown, FaCheck, FaEnvelope, FaHistory, FaSignOutAlt} from "react-icons/fa";
import {getAPIImage, onLogoutClick, sendGet, sendUpdate, uploadPicture} from "../../../utils";
import {useEffect, useState} from "react";

const ProfileNavbar = ({ProfilePage, SetProfilePage}) => {
    const subPages = [
        {
            text: "Personal Information",
            icon: <FaPerson/>,
            active: false,
            subpage: "#",
            disabled: false
        },
        {
            text: "Password",
            icon: <FaUserLock/>,
            active: false,
            subpage: "#password",
            disabled: false
        },
        {
            text: "E-Mail",
            icon: <FaEnvelope/>,
            active: false,
            subpage: "#email",
            disabled: false
        },
        {
            text: "Notification",
            icon: <FaBell/>,
            active: false,
            subpage: "#notification",
            disabled: false
        },
        {
            text: "Payment Methods",
            icon: <FaCreditCard/>,
            active: false,
            subpage: "#payment-methods",
            disabled: true
        },
        {
            text: "History",
            icon: <FaHistory/>,
            active: false,
            subpage: "#order-history",
            disabled: true
        },
        {
            text: "Achievements",
            icon: <FaTrophy/>,
            active: false,
            subpage: "#achievements",
            disabled: true
        },
        {
            text: "Account Deletion",
            icon: <FaCircleMinus/>,
            active: false,
            subpage: "#delete-account",
            disabled: false
        }
    ];

    let hasActivePage = false;
    subPages.forEach(page => {
        page.active = false;
        if (page.subpage === ProfilePage) {
            page.active = true;
            hasActivePage = true;
        }
    });
    if (!hasActivePage) subPages[0].active = true;

    const [UserData, SetUserData] = useState(null);
    useEffect(() => {
        const f = async () => {
            const res = await sendGet("crisestudios/profile/get/navbar_header");
            if (res.status === "ok") {
                SetUserData(res.data);
                SetUserTitle(res.data.titles.selected);
            } else await onLogoutClick(true);
        }
        f();
    }, []);

    const [UserTitle, SetUserTitle] = useState(null);
    const [TitleDropdown, ToggleTitleDropdown] = useState(false);
    const _ChangeSelectedTitle = async (title = '') => {
        if (UserData === null) return;
        if (UserTitle === null) return;
        if (UserTitle.uuid === title.uuid) return;
        if (TitleDropdown) ToggleTitleDropdown(false);

        let res = await sendUpdate("crisestudios/profile/update/title", {
            titleId: title.uuid
        });

        if (res.status === "ok") SetUserTitle(title);
    }

    return <div id="profile-navbar">
        <div className="card header">
            <div className="picture" onClick={() => {
                if (ProfilePage !== "#profile-picture") SetProfilePage("#profile-picture");
            }}>
                {UserData !== null && (
                    <img src={getAPIImage("crisestudios/user_images/" + UserData.picture)} alt="" draggable="false"
                         id="navbarProfilePicture"/>)}
                <FaCamera/>
            </div>
            <div className="full-name">
                {UserData !== null && (UserData.firstname + " " + UserData.lastname)}
            </div>
            <div className="username">
                {UserData !== null && ("@" + UserData.username)}
            </div>
            <div className="user-status-display">
                <div className="label">Role</div>
                <div className="inner">
                    <span
                        style={{color: (UserData !== null ? UserData.role.color : "")}}>{UserData !== null && UserData.role.name}</span>
                </div>

                <div className="slope_left"/>
                <div className="slope_right"/>
            </div>
            <div className="user-status-display user-title-display">
                <div className="label">Title</div>
                <div className="inner" onClick={() => {
                    ToggleTitleDropdown(!TitleDropdown);
                }}>
                    <span>{UserTitle !== null && UserTitle.name}</span>
                    <FaCaretDown className={TitleDropdown ? "show" : ""}/>
                </div>
                <div className="slope_left"/>
                <div className="slope_right"/>

                <div className={"user-status-display-dropdown " + (TitleDropdown ? "show" : "")}>
                    {UserData !== null && UserData.titles.titles.map((title, i) => {
                        return <div
                            className={title.uuid === UserTitle.uuid ? "selected" : ""}
                            key={i}
                            onClick={() => {
                                _ChangeSelectedTitle(title);
                            }}
                        >
                            <span>{title.name}</span>
                            {title.uuid === UserTitle.uuid && (<FaCheck/>)}
                        </div>
                    })}
                </div>
            </div>
        </div>
        <div className="card">
            {subPages.map((page, i) => {
                return <a key={i} href={page.subpage} aria-disabled={page.disabled}
                          className={"page-link " + (ProfilePage === page.subpage ? "active" : "") + " " + (page.disabled ? "disabled" : "")}
                          onClick={() => {
                              if (page.disabled) return;
                              if (ProfilePage !== page.subpage.toLowerCase()) SetProfilePage(page.subpage.toLowerCase());
                              /*setTimeout(() => {
                                  document.location.reload();
                              }, 10);*/
                          }}>
                    <div>
                        {page.icon} {page.text}
                    </div>
                </a>;
            })}
        </div>

        <div className="card card2">
            <a href="/support" className="link support">
                <div>
                    <FaHandshakeAngle/> Need help?
                </div>
            </a>
        </div>
        <div className="card card2">
            <a onClick={onLogoutClick} className="link logout">
                <div>
                    <FaSignOutAlt/> Sign Out
                </div>
            </a>
        </div>
    </div>;
}

ProfileNavbar.defaultProps = {}
export default ProfileNavbar;