import './ProjectsView.css'
import {getAPIImage, getQuery, sendGet, setTitle} from "../../utils";
import {FaTicketAlt, FaComments, FaMailBulk, FaAngleLeft, FaAngleRight} from "react-icons/fa";
import {useEffect, useRef, useState} from "react";

import NoProjectImageFound from '../../images/no_project_image.png';

const Projects = ({title}) => {
    const projectId = getQuery("project");
    const pageTitle = useRef(title);
    setTitle(pageTitle.current);

    const [isLoading, setIsLoading] = useState(true);
    const [showError, setShowError] = useState("");
    const [show, setShow] = useState(0);
    const [images, setImages] = useState([]);

    const [Data, SetData] = useState(null);


    const previousImage = () => {
        let index = show - 1;
        if (index < 0) index = images.length - 1;
        setShow(index);
    }
    const nextImage = () => {
        let index = show + 1;
        if (index >= images.length) index = 0;
        setShow(index);
    }


    useEffect(() => {
        setShowError("");
        if (projectId === "" || projectId === undefined) {
            setShowError("projectId not provided!");
            return;
        }
        sendGet("crisestudios/projects/" + projectId)
            .then(response => {
                if (response.status === "ok") {
                    const data = response.data;

                    let type = data.type;
                    type = type.substring(0, 1).toUpperCase() + type.substring(1, type.length);
                    if (type.includes("-")) {
                        let start = type.indexOf("-");
                        let first = type.substring(0, start + 1);
                        let letter = type.substring(start + 1, start + 2).toUpperCase();
                        let end = type.substring(start + 2, type.length);
                        type = first + letter + end;
                    }
                    data.type = type;

                    SetData(data);

                    let imgs = [];
                    if (data.thumbnail !== "") imgs.push(data.thumbnail);
                    imgs.push(...data.images);
                    setImages(imgs);

                    setTimeout(() => {
                        document.querySelector(".description").innerHTML = data.description;
                        setIsLoading(false);
                        pageTitle.current = "Project: " + data.name;

                        setTimeout(() => {
                            setTitle(pageTitle.current);
                        }, 50);
                    }, 250);
                } else setShowError("Project '" + projectId + "' not found!");
            })
            .catch(err => {
                setShowError("Project '" + projectId + "' not found!");
            });
    }, [pageTitle, setIsLoading, setShowError, projectId, setImages, SetData]);


    return (
        <div className="projects_view-container">
            <div className="header">
                <div className="header-content">
                    <div className="title">
                        {isLoading && showError === "" ? 'Loading Project Information..' : showError !== "" ? showError : (
                            'Project Information'
                        )}
                    </div>
                    <div className="project-logo">
                        {Data !== null && (<img src={Data.logo} alt="" draggable="false"/>)}
                    </div>
                </div>
            </div>
            <div className="project-content">
                <div className="content-left">
                    <div className={isLoading ? 'project-images skeleton' : 'project-images'}>
                        {!isLoading && (<>
                            <div className="overlay">
                            <div className="project-type">{Data !== null && Data.type}</div>
                                {images.length > 1 ? (
                                    <div className="controller previous" onClick={previousImage}><FaAngleLeft/>
                                    </div>) : ''}
                                {images.length > 1 ? (
                                    <div className="controller next" onClick={nextImage}><FaAngleRight/></div>) : ''}
                            </div>
                            <div className="images noselect" style={{left: "calc(-" + show + "*100%)"}}>
                                {
                                    images.length > 0 ? images.map((image, index) => {
                                        return <img key={index} src={getAPIImage(image)} alt="" draggable="false"/>;
                                    }) : (
                                        <img src={NoProjectImageFound} alt="" draggable="false"/>
                                    )
                                }
                            </div>
                        </>)}
                    </div>
                    <div className="project-description">
                        <div className="title">{!isLoading ? (Data !== null && Data.name) : (<>
                            <div className="skeleton skeleton-text"></div>
                            <div className="skeleton skeleton-text"></div>
                        </>)}</div>
                        <div className="description">
                            <div className="skeleton skeleton-text"></div>
                            <div className="skeleton skeleton-text"></div>
                            <div className="skeleton skeleton-text"></div>
                            <div className="skeleton skeleton-text"></div>
                        </div>
                    </div>
                </div>
                <div className="content-right">
                    <div className="content-container">
                        {isLoading ?
                            (<>
                                <div className="entry">
                                    <div className="text">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                    <div className="value">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                </div>
                                <div className="entry">
                                    <div className="text">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                    <div className="value">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                </div>
                                <div className="entry">
                                    <div className="text">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                    <div className="value">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                </div>
                                <div className="entry">
                                    <div className="text">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                    <div className="value">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                </div>
                                <div className="entry">
                                    <div className="text">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                    <div className="value">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                </div>
                                <div className="entry">
                                    <div className="text">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                    <div className="value">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                </div>
                                <div className="entry">
                                    <div className="text">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                    <div className="value">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                </div>
                                <div className="entry">
                                    <div className="text">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                    <div className="value">
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                </div>
                            </>) :
                            (<>
                                {
                                    Data.details.map((detail, i) => {
                                        let detailName = detail.name;
                                        let detailValue = "";

                                        if(detail.type === "text") detailValue = detail.text;
                                        if(detail.type === "link") detailValue = <a href={detail.link} rel={"noreferrer"} target={"_blank"}>{detail.text}</a>;
                                        if(detail.type === "list") {
                                            for(let e of detail.list) detailValue += e + ", ";
                                            detailValue = detailValue.substring(0, detailValue.length - 2);
                                        }


                                        return <div className="entry" key={i}>
                                                <div className="text">{detailName}</div>
                                                <div className="value">{detailValue}</div>
                                            </div>;
                                    })
                                }
                            </>)}
                    </div>
                    <div className="content-container">
                        <div className="title">{!isLoading ? 'Need Support?' : (<>
                            <div className="skeleton skeleton-text"></div>
                            <div className="skeleton skeleton-text"></div>
                        </>)}</div>
                        {isLoading ? (<>
                                <br/>
                                <div className="link">
                                    <div className="skeleton skeleton-text"></div>
                                </div>
                                <div className="link">
                                    <div className="skeleton skeleton-text"></div>
                                </div>
                                <div className="link">
                                    <div className="skeleton skeleton-text"></div>
                                </div>
                            </>) :
                            (<>
                                <a href="/support">
                                    <div className="link"><FaTicketAlt/> Ticket Support</div>
                                </a>
                                <a href="/faq">
                                    <div className="link"><FaComments/> FAQ</div>
                                </a>
                                <a href="mailto:contact@crisestudios.com">
                                    <div className="link"><FaMailBulk/> contact@crisestudios.com</div>
                                </a>
                            </>)}
                    </div>
                </div>
            </div>
        </div>
    );
}

Projects.defaultProps = {
    title: 'Loading..'
}

export default Projects;