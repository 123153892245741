import './Service.css'
import {setTitle} from "../../utils";

import logo from '../../images/logos/logo_square_v1_blurred.png';

const TermsOfService = ({ title }) => {
    setTitle(title);


    /*useEffect(() => {
        const wrapper = document.querySelector('.service-container-content');

        const fetchTos = async () => {
            let response = await sendPost('crisestudios/service/tos');
            if(response.status === 'ok') {
                wrapper.innerHTML = '';
                const data = response.data;
                const contents = data.contents;
                const lastUpdated = data.latest_update;

                for(let content of contents) {
                    wrapper.innerHTML +=
                        `
                        <div class="box">
                            <div class="title">`+content.title+`</div>
                            <div class="content">`+content.content.replace('\n', '<br />')+`</div>
                        </div>
                        `;
                }

                wrapper.innerHTML +=
                    `
                    <p><strong>Last updated:</strong> `+lastUpdated+`</p>
                    `;
            }
            else {
                wrapper.innerHTML +=
                    `
                    <p><strong>Last updated:</strong> An error occurred.</p>
                    `;

                wrapper.innerHTML += `
                    <div class="error_message">
                        Something went wrong while loading the "Terms of Service" page.
                        <br />
                        That means that you are probably seeing an older version of our "Terms of Service" page
                    </div>`;
            }
        }
        fetchTos();
    });*/


    return (
        <div className="container_bgimage" style={{backgroundImage: 'url('+logo+')'}}>
            <div className="service-container tos">
                <h1>Terms of Service</h1>
                <h2>Website Terms and Conditions of Use</h2>

                <div className="divider">
                    <div className="left"></div>
                    <div className="center"></div>
                    <div className="right"></div>
                </div>

                <div className="service-container-content">
                    <div className="box">
                        <div className="title">1. Terms</div>
                        <div className="content">By accessing this Website, accessible from <a target="_blank" rel="noreferrer" href="https://crisestudios.com/">https://crisestudios.com/</a>,
                            you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you
                            are responsible for the agreement with any applicable local laws.<br />If you disagree with
                                any of these terms, you are prohibited from accessing this site.
                                The materials contained in this Website are protected by copyright and trade mark law.
                        </div>
                    </div>

                    <div className="box">
                        <div className="title">2. Use License</div>
                        <div className="content">Permission is granted to temporarily download one copy of the materials
                            on CriseStudios's Website for personal, non-commercial transitory viewing only.<br /> This is
                                the grant of a license, not a transfer of title, and under this license you may not:
                                <br />
                                    <ul>
                                        <li>modify or copy the materials;</li>
                                        <li>use the materials for any commercial purpose or for any public display;</li>
                                        <li>attempt to reverse engineer any software contained on CriseStudios's
                                            Website;
                                        </li>
                                        <li>remove any copyright or other proprietary notations from the materials; or
                                        </li>
                                        <li>transferring the materials to another person or "mirror" the materials on
                                            any other server.
                                        </li>
                                    </ul>
                                    This will let CriseStudios to terminate upon violations of any of these
                                    restrictions.
                                    Upon termination, your viewing right will also be terminated and you should destroy
                                    any downloaded materials in your possession whether it is printed or electronic
                                    format.
                                    These Terms of Service has been created with the help of the Terms Of Service
                                    Generator.</div>
                    </div>

                    <div className="box">
                        <div className="title">2.2 Account Use License</div>
                        <div className="content">All accounts are the property of CriseStudios and permission is granted
                            to private usage only.
                            <br /> <br />Under this license you may not: <br />
                                <ul>
                                    <li>share the access information with another person;</li>
                                    <li>transfer the account ownership to another person;</li>
                                    <li>selling or offering for sale the account or any sort of data associated with the
                                        account for monetary value or goods;
                                    </li>
                                </ul>
                                This license shall automatically terminate if you violate any of these restrictions and
                                may be terminated by CriseStudios at any time. Upon terminating your viewing of these
                                materials or upon the termination of this license, you must destroy any downloaded
                                materials in your possession whether in electronic or printed format.</div>
                    </div>

                    <div className="box">
                        <div className="title">2.3 Naming Policy</div>
                        <div className="content">Any data that can be submitted to our platform is subject to our
                            content and naming policy. Names, texts, images and other forms of data submitted to our
                            platform may not contain:
                            <br />
                                <ol>
                                    <li>offensive language;</li>
                                    <li>racism;</li>
                                    <li>pornography or inappropriate, sexual content;</li>
                                    <li>hate speech;</li>
                                </ol>
                                This license shall automatically terminate if you violate any of these restrictions and
                                may be terminated by CriseStudios at any time.
                                Upon terminating your viewing of these materials or upon the termination of this
                                license, you must destroy any downloaded materials in your possession whether in
                                electronic or printed format.</div>
                    </div>

                    <div className="box">
                        <div className="title">3. Disclaimer</div>
                        <div className="content">All the materials on CriseStudios’s Website are provided "as is".
                            <br /> CriseStudios makes no warranties, may it be expressed or implied, therefore negates all
                                other warranties.
                                Furthermore, CriseStudios does not make any representations concerning the accuracy or
                                reliability of the use of the materials on its Website or otherwise relating to such
                                materials or any sites linked to this Website.</div>
                    </div>

                    <div className="box">
                        <div className="title">4. Limitations</div>
                        <div className="content">CriseStudios or its suppliers will not be hold accountable for any
                            damages that will arise with the use or inability to use the materials on CriseStudios’s
                            Website,
                            <br /> even if CriseStudios or an authorize representative of this Website has been notified,
                                orally or written, of the possibility of such damage.
                                Some jurisdiction does not allow limitations on implied warranties or limitations of
                                liability for incidental damages, these limitations may not apply to you.</div>
                    </div>

                    <div className="box">
                        <div className="title">5. Revisions and Errata</div>
                        <div className="content">The materials appearing on CriseStudios’s Website may include
                            technical, typographical, or photographic errors.
                            <br /> CriseStudios will not promise that any of the materials in this Website are accurate,
                                complete, or current.
                                CriseStudios may change the materials contained on its Website at any time without
                                notice.
                                CriseStudios does not make any commitment to update the materials.</div>
                    </div>

                    <div className="box">
                        <div className="title">6. Links</div>
                        <div className="content">CriseStudios has not reviewed all of the sites linked to its Website
                            and is not responsible for the contents of any such linked site.
                            <br /> The presence of any link does not imply endorsement by CriseStudios of the site.
                                The use of any linked website is at the user’s own risk.</div>
                    </div>

                    <div className="box">
                        <div className="title">7. Site Terms of Use Modifications</div>
                        <div className="content">CriseStudios may revise these Terms of Use for its Website at any time
                            without prior notice.
                            <br /> By using this Website, you are agreeing to be bound by the current version of these
                                Terms and Conditions of Use.</div>
                    </div>

                    <div className="box">
                        <div className="title">8. Your Privacy</div>
                        <div className="content">Please read our <a href="/privacy">Privacy Policy</a>.</div>
                    </div>

                    <div className="box">
                        <div className="title">9. Governing Law</div>
                        <div className="content">Any claim related to CriseStudios's Website shall be governed by the
                            laws of Germany without regards to its conflict of law provisions.
                        </div>
                    </div>

                    <p><strong>Last updated:</strong> 14.07.2023</p>
                </div>
            </div>
        </div>
    );
}

TermsOfService.defaultProps = {
    title: 'Terms of Service'
}

export default TermsOfService;