import {FaCircleMinus} from "react-icons/fa6";
import StyledInput from "../../styledinput/StyledInput";
import {FaExclamationCircle, FaInfoCircle} from "react-icons/fa";
import {useState} from "react";

const ProfileContent_AccountDeletion = () => {

    const PageTitle = "Account Deletion";
    const PageSubTitle = "We're sorry to hear that you're considering deleting your account. We hope you've enjoyed your time as part of our community and wish you all the best!";
    const PageIcon = <FaCircleMinus/>;

    const [Error, ShowError] = useState("");

    const _deleteAccount = async () => {
        if (Error !== "") ShowError("");

        let password = document.getElementById("inputPasswordOne").value;
        let passwordRepeat = document.getElementById("inputPasswordTwo").value;

        if(password.length <= 0 || passwordRepeat.length <= 0) {
            ShowError("Please fill out both password forms");
            return;
        }
        if(password !== passwordRepeat) {
            ShowError("The passwords do not match");
            return;
        }

        //TODO: Send API POST

    }

    return <>
        <div className="header">
            <div className="icon">
                {PageIcon}
            </div>
            <div className="titles">
                <div className="title">{PageTitle}</div>
                <div className="sub-title">{PageSubTitle}</div>
            </div>
        </div>


        <div className="input-row">
            <StyledInput
                id="inputPasswordOne"
                className="row-input-box"
                inputType="password"
                label="Password"
            />
            <StyledInput
                id="inputPasswordTwo"
                className="row-input-box"
                inputType="password"
                label="Confirm Password"
            />
        </div>

        {Error !== "" && (<div className="error-text">
            <FaExclamationCircle/>
            <div className="text">
                {Error}
            </div>
        </div>)}

        < div className="info2">
            <FaInfoCircle/>
            <div className="text">
                Before proceeding, please carefully consider the decision to delete your account, as once it's deleted, it cannot be recoverd.
            </div>
        </div>

        <div className="row">
            <button className="profile-btn-red" style={{marginRight: "15px"}} onClick={_deleteAccount}>Delete Account
            </button>
            <button className="profile-btn-gray" onClick={() => {
                document.location.reload();
            }}>Cancel
            </button>
        </div>
    </>;
}

ProfileContent_AccountDeletion.props = {};
export default ProfileContent_AccountDeletion;